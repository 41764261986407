import React, { useEffect, useState } from 'react';
import Logo from '../res/img/logo.svg'
import LogoMobile from '../res/img/logo-mobile.svg'
import { useTranslation } from 'react-i18next';


function Terms() {
    const [scrolled, setScrolled] = useState(false);
    const { t } = useTranslation();
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth' // Scroll fluide
            });
        }
    };


    return (
        <>

            <nav style={{ top: '0' }} className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`} >

                <img onClick={() => window.open("https://starmaze.io")} style={{ cursor: 'pointer' }} className='logoStarmaze' src={Logo} alt="logo-starmaze" />
                <img onClick={() => window.open("https://starmaze.io")} style={{ cursor: 'pointer' }} className='logoStarmazeMobile' src={LogoMobile} alt="logo-starmaze" />


            </nav>


            <div style={{ marginInline: '16%' }} >
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="title"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>TERMS AND CONDITIONS</strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="subtitle"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>Last updated</strong> <strong>March 06, 2024</strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <strong>
                            <span data-custom-class="heading_1">
                                AGREEMENT TO OUR LEGAL TERMS
                            </span>
                        </strong>
                    </div>
                    <p data-custom-class="title">
                        <strong>TERMS AND CONDITIONS</strong>
                    </p>
                    <p data-custom-class="subtitle">
                        <strong>Last updated</strong>
                        <strong>March 06, 2024</strong>
                    </p>
                    <p>
                        <strong>
                            <span data-custom-class="heading_1">
                                AGREEMENT TO OUR LEGAL TERMS
                            </span>
                        </strong>
                    </p>
                    <p data-custom-class="body_text">
                        We are Starmaze, doing business as Starmaze ("<strong>Company</strong>,"
                        "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>").
                    </p>
                    <p data-custom-class="body_text">
                        We operate the website{" "}
                        <a href="https://starmaze.io/astrokeeper" data-custom-class="link">
                            https://starmaze.io/astrokeeper
                        </a>{" "}
                        (the "<strong>Site</strong>"), as well as any other related products and
                        services that refer or link to these legal terms (the "
                        <strong>Legal Terms</strong>") (collectively, the "
                        <strong>Services</strong>").
                    </p>
                    <p data-custom-class="body_text">
                        You can contact us by email at paul@starmaze.io or by mail to
                        __________, __________,&nbsp;France.
                    </p>
                    <p data-custom-class="body_text">
                        These Legal Terms constitute a legally binding agreement made between
                        you, whether personally or on behalf of an entity ("<strong>you</strong>
                        "), and Starmaze, concerning your access to and use of the Services. You
                        agree that by accessing the Services, you have read, understood, and
                        agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
                        ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
                        THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>
                    <p data-custom-class="body_text">
                        Supplemental terms and conditions or documents that may be posted on the
                        Services from time to time are hereby expressly incorporated herein by
                        reference. We reserve the right, in our sole discretion, to make changes
                        or modifications to these Legal Terms from time to time. We will alert
                        you about any changes by updating the "Last updated" date of these Legal
                        Terms, and you waive any right to receive specific notice of each such
                        change. It is your responsibility to periodically review these Legal
                        Terms to stay informed of updates. You will be subject to, and will be
                        deemed to have been made aware of and to have accepted, the changes in
                        any revised Legal Terms by your continued use of the Services after the
                        date such revised Legal Terms are posted.
                    </p>
                    <p data-custom-class="body_text">
                        All users who are minors in the jurisdiction in which they reside
                        (generally under the age of 18) must have the permission of, and be
                        directly supervised by, their parent or guardian to use the Services. If
                        you are a minor, you must have your parent or guardian read and agree to
                        these Legal Terms prior to you using the Services.
                    </p>
                    <p data-custom-class="body_text">
                        We recommend that you print a copy of these Legal Terms for your
                        records.
                    </p>
                    <p data-custom-class="heading_1">
                        <strong>TABLE OF CONTENTS</strong>
                    </p>
                    <ol>
                        <li>
                            <a href="https://wordtohtml.net/#services">
                                <span data-custom-class="link">
                                    <span data-custom-class="body_text">&nbsp;OUR SERVICES</span>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#ip" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;INTELLECTUAL PROPERTY RIGHTS
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#userreps" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;USER REPRESENTATIONS
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#userreg" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;USER REGISTRATION</span>
                            </a>
                        </li>
                    </ol>
                    <ol start={5}>
                        <li>
                            <a href="https://wordtohtml.net/#purchases" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;PURCHASES AND PAYMENT
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://wordtohtml.net/#subscriptions"
                                data-custom-class="link"
                            >
                                <span data-custom-class="body_text">&nbsp;SUBSCRIPTIONS</span>
                            </a>
                        </li>
                    </ol>
                    <ol start={7}>
                        <li>
                            <a href="https://wordtohtml.net/#software" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;SOFTWARE</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#prohibited" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;PROHIBITED ACTIVITIES
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#ugc" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;USER GENERATED CONTRIBUTIONS
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#license" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;CONTRIBUTION LICENSE
                                </span>
                            </a>
                        </li>
                    </ol>
                    <ol start={11}>
                        <li>
                            <a href="https://wordtohtml.net/#thirdparty" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;THIRD-PARTY WEBSITES AND CONTENT
                                </span>
                            </a>
                        </li>
                    </ol>
                    <ol start={12}>
                        <li>
                            <a href="https://wordtohtml.net/#sitemanage" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;SERVICES MANAGEMENT</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#ppyes" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;PRIVACY POLICY</span>
                            </a>
                        </li>
                    </ol>
                    <ol start={14}>
                        <li>
                            <a href="https://wordtohtml.net/#terms" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;TERM AND TERMINATION
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://wordtohtml.net/#modifications"
                                data-custom-class="link"
                            >
                                <span data-custom-class="body_text">
                                    &nbsp;MODIFICATIONS AND INTERRUPTIONS
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#law" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;GOVERNING LAW</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#disputes" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;DISPUTE RESOLUTION</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://wordtohtml.net/#corrections"
                                data-custom-class="link"
                            >
                                <span data-custom-class="body_text">&nbsp;CORRECTIONS</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#disclaimer" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;DISCLAIMER</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#liability" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;LIMITATIONS OF LIABILITY
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://wordtohtml.net/#indemnification"
                                data-custom-class="link"
                            >
                                <span data-custom-class="body_text">&nbsp;INDEMNIFICATION</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#userdata" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;USER DATA</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#electronic" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#california" data-custom-class="link">
                                <span data-custom-class="body_text">
                                    &nbsp;CALIFORNIA USERS AND RESIDENTS
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://wordtohtml.net/#misc" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;MISCELLANEOUS</span>
                            </a>
                        </li>
                    </ol>
                    <ol start={26}>
                        <li>
                            <a href="https://wordtohtml.net/#contact" data-custom-class="link">
                                <span data-custom-class="body_text">&nbsp;CONTACT US</span>
                            </a>
                        </li>
                    </ol>
                    <ol>
                        <li data-custom-class="heading_1">
                            <strong>&nbsp;OUR SERVICES</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        The information provided when using the Services is not intended for
                        distribution to or use by any person or entity in any jurisdiction or
                        country where such distribution or use would be contrary to law or
                        regulation or which would subject us to any registration requirement
                        within such jurisdiction or country. Accordingly, those persons who
                        choose to access the Services from other locations do so on their own
                        initiative and are solely responsible for compliance with local laws, if
                        and to the extent local laws are applicable.
                    </p>
                    <p data-custom-class="body_text">
                        The Services are not tailored to comply with industry-specific
                        regulations (Health Insurance Portability and Accountability Act
                        (HIPAA), Federal Information Security Management Act (FISMA), etc.), so
                        if your interactions would be subjected to such laws, you may not use
                        the Services. You may not use the Services in a way that would violate
                        the Gramm-Leach-Bliley Act (GLBA).
                    </p>
                    <ol start={2}>
                        <li data-custom-class="heading_1">
                            <strong>&nbsp;INTELLECTUAL PROPERTY RIGHTS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="heading_2">
                        <strong>Our intellectual property</strong>
                    </p>
                    <p data-custom-class="body_text">
                        We are the owner or the licensee of all intellectual property rights in
                        our Services, including all source code, databases, functionality,
                        software, website designs, audio, video, text, photographs, and graphics
                        in the Services (collectively, the "Content"), as well as the
                        trademarks, service marks, and logos contained therein (the "Marks").
                    </p>
                    <p data-custom-class="body_text">
                        Our Content and Marks are protected by copyright and trademark laws (and
                        various other intellectual property rights and unfair competition laws)
                        and treaties in the United States and around the world.
                    </p>
                    <p data-custom-class="body_text">
                        The Content and Marks are provided in or through the Services "AS IS"
                        for your personal, non-commercial use or internal business purpose only.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Your use of our Services</strong>
                    </p>
                    <p data-custom-class="body_text">
                        Subject to your compliance with these Legal Terms, including the "
                        <a href="https://wordtohtml.net/#prohibited" data-custom-class="link">
                            PROHIBITED ACTIVITIES
                        </a>
                        " section below, we grant you a non-exclusive, non-transferable,
                        revocable license to:
                    </p>
                    <ul>
                        <li data-custom-class="body_text">access the Services; and</li>
                        <li data-custom-class="body_text">
                            download or print a copy of any portion of the Content to which you
                            have properly gained access.
                        </li>
                    </ul>
                    <p data-custom-class="body_text">
                        solely for your personal, non-commercial use or internal business
                        purpose.
                    </p>
                    <p data-custom-class="body_text">
                        Except as set out in this section or elsewhere in our Legal Terms, no
                        part of the Services and no Content or Marks may be copied, reproduced,
                        aggregated, republished, uploaded, posted, publicly displayed, encoded,
                        translated, transmitted, distributed, sold, licensed, or otherwise
                        exploited for any commercial purpose whatsoever, without our express
                        prior written permission.
                    </p>
                    <p data-custom-class="body_text">
                        If you wish to make any use of the Services, Content, or Marks other
                        than as set out in this section or elsewhere in our Legal Terms, please
                        address your request to: paul@starmaze.io. If we ever grant you the
                        permission to post, reproduce, or publicly display any part of our
                        Services or Content, you must identify us as the owners or licensors of
                        the Services, Content, or Marks and ensure that any copyright or
                        proprietary notice appears or is visible on posting, reproducing, or
                        displaying our Content.
                    </p>
                    <p data-custom-class="body_text">
                        We reserve all rights not expressly granted to you in and to the
                        Services, Content, and Marks.
                    </p>
                    <p data-custom-class="body_text">
                        Any breach of these Intellectual Property Rights will constitute a
                        material breach of our Legal Terms and your right to use our Services
                        will terminate immediately.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Your submissions and contributions</strong>
                    </p>
                    <p data-custom-class="body_text">
                        Please review this section and the "
                        <a href="https://wordtohtml.net/#prohibited" data-custom-class="link">
                            PROHIBITED ACTIVITIES
                        </a>
                        " section carefully prior to using our Services to understand the (a)
                        rights you give us and (b) obligations you have when you post or upload
                        any content through the Services.
                    </p>
                    <p data-custom-class="body_text">
                        <strong>Submissions:</strong> By directly sending us any question,
                        comment, suggestion, idea, feedback, or other information about the
                        Services ("Submissions"), you agree to assign to us all intellectual
                        property rights in such Submission. You agree that we shall own this
                        Submission and be entitled to its unrestricted use and dissemination for
                        any lawful purpose, commercial or otherwise, without acknowledgment or
                        compensation to you.
                    </p>
                    <p data-custom-class="body_text">
                        <strong>Contributions:</strong> The Services may invite you to chat,
                        contribute to, or participate in blogs, message boards, online forums,
                        and other functionality during which you may create, submit, post,
                        display, transmit, publish, distribute, or broadcast content and
                        materials to us or through the Services, including but not limited to
                        text, writings, video, audio, photographs, music, graphics, comments,
                        reviews, rating suggestions, personal information, or other material
                        ("Contributions"). Any Submission that is publicly posted shall also be
                        treated as a Contribution.
                    </p>
                    <p data-custom-class="body_text">
                        You understand that Contributions may be viewable by other users of the
                        Services and possibly through third-party websites.
                    </p>
                    <p data-custom-class="body_text">
                        <strong>
                            When you post Contributions, you grant us a license (including use of
                            your name, trademarks, and logos):&nbsp;
                        </strong>
                        By posting any Contributions, you grant us an unrestricted, unlimited,
                        irrevocable, perpetual, non-exclusive, transferable, royalty-free,
                        fully-paid, worldwide right, and license to: use, copy, reproduce,
                        distribute, sell, resell, publish, broadcast, retitle, store, publicly
                        perform, publicly display, reformat, translate, excerpt (in whole or in
                        part), and exploit your Contributions (including, without limitation,
                        your image, name, and voice) for any purpose, commercial, advertising,
                        or otherwise, to prepare derivative works of, or incorporate into other
                        works, your Contributions, and to sublicense the licenses granted in
                        this section. Our use and distribution may occur in any media formats
                        and through any media channels.
                    </p>
                    <p data-custom-class="body_text">
                        This license includes our use of your name, company name, and franchise
                        name, as applicable, and any of the trademarks, service marks, trade
                        names, logos, and personal and commercial images you provide.
                    </p>
                    <p data-custom-class="body_text">
                        <strong>You are responsible for what you post or upload:</strong> By
                        sending us Submissions and/or posting Contributions through any part of
                        the Services or making Contributions accessible through the Services by
                        linking your account through the Services to any of your social
                        networking accounts, you:
                    </p>
                    <ul>
                        <li data-custom-class="body_text">
                            confirm that you have read and agree with our "
                            <a href="https://wordtohtml.net/#prohibited" data-custom-class="link">
                                PROHIBITED ACTIVITIES
                            </a>
                            " and will not post, send, publish, upload, or transmit through the
                            Services any Submission nor post any Contribution that is illegal,
                            harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
                            discriminatory, threatening to any person or group, sexually explicit,
                            false, inaccurate, deceitful, or misleading;
                        </li>
                        <li data-custom-class="body_text">
                            to the extent permissible by applicable law, waive any and all moral
                            rights to any such Submission and/or Contribution;
                        </li>
                        <li data-custom-class="body_text">
                            warrant that any such Submission and/or Contributions are original to
                            you or that you have the necessary rights and licenses to submit such
                            Submissions and/or Contributions and that you have full authority to
                            grant us the above-mentioned rights in relation to your Submissions
                            and/or Contributions; and
                        </li>
                        <li data-custom-class="body_text">
                            warrant and represent that your Submissions and/or Contributions do
                            not constitute confidential information.
                        </li>
                    </ul>
                    <p data-custom-class="body_text">
                        You are solely responsible for your Submissions and/or Contributions and
                        you expressly agree to reimburse us for any and all losses that we may
                        suffer because of your breach of (a) this section, (b) any third party’s
                        intellectual property rights, or (c) applicable law.
                    </p>
                    <p data-custom-class="body_text">
                        <strong>We may remove or edit your Content:</strong> Although we have no
                        obligation to monitor any Contributions, we shall have the right to
                        remove or edit any Contributions at any time without notice if in our
                        reasonable opinion we consider such Contributions harmful or in breach
                        of these Legal Terms. If we remove or edit any such Contributions, we
                        may also suspend or disable your account and report you to the
                        authorities.
                    </p>
                    <ol start={3}>
                        <li data-custom-class="heading_1">
                            <strong>USER REPRESENTATIONS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        By using the Services, you represent and warrant that: (1) all
                        registration information you submit will be true, accurate, current, and
                        complete; (2) you will maintain the accuracy of such information and
                        promptly update such registration information as necessary; (3) you have
                        the legal capacity and you agree to comply with these Legal Terms; (4)
                        you are not a minor in the jurisdiction in which you reside, or if a
                        minor, you have received parental permission to use the Services; (5)
                        you will not access the Services through automated or non-human means,
                        whether through a bot, script or otherwise; (6) you will not use the
                        Services for any illegal or unauthorized purpose; and (7) your use of
                        the Services will not violate any applicable law or regulation.
                    </p>
                    <p data-custom-class="body_text">
                        If you provide any information that is untrue, inaccurate, not current,
                        or incomplete, we have the right to suspend or terminate your account
                        and refuse any and all current or future use of the Services (or any
                        portion thereof).
                    </p>
                    <ol start={4}>
                        <li data-custom-class="heading_1">
                            <strong>USER REGISTRATION</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        You may be required to register to use the Services. You agree to keep
                        your password confidential and will be responsible for all use of your
                        account and password. We reserve the right to remove, reclaim, or change
                        a username you select if we determine, in our sole discretion, that such
                        username is inappropriate, obscene, or otherwise objectionable.
                    </p>
                    <ol start={5}>
                        <li data-custom-class="heading_1">
                            <strong>PURCHASES AND PAYMENT</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        We accept the following forms of payment:
                    </p>
                    <p data-custom-class="body_text">- Visa</p>
                    <p data-custom-class="body_text">- Mastercard</p>
                    <p data-custom-class="body_text">- American Express</p>
                    <p data-custom-class="body_text">- PayPal</p>
                    <p data-custom-class="body_text">
                        You agree to provide current, complete, and accurate purchase and
                        account information for all purchases made via the Services. You further
                        agree to promptly update account and payment information, including
                        email address, payment method, and payment card expiration date, so that
                        we can complete your transactions and contact you as needed. Sales tax
                        will be added to the price of purchases as deemed required by us. We may
                        change prices at any time. All payments shall be&nbsp;in Euros.
                    </p>
                    <p data-custom-class="body_text">
                        You agree to pay all charges at the prices then in effect for your
                        purchases and any applicable shipping fees, and you authorize us to
                        charge your chosen payment provider for any such amounts upon placing
                        your order. We reserve the right to correct any errors or mistakes in
                        pricing, even if we have already requested or received payment.
                    </p>
                    <p data-custom-class="body_text">
                        We reserve the right to refuse any order placed through the Services. We
                        may, in our sole discretion, limit or cancel quantities purchased per
                        person, per household, or per order. These restrictions may include
                        orders placed by or under the same customer account, the same payment
                        method, and/or orders that use the same billing or shipping address. We
                        reserve the right to limit or prohibit orders that, in our sole
                        judgment, appear to be placed by dealers, resellers, or distributors.
                    </p>
                    <ol start={6}>
                        <li data-custom-class="heading_1">
                            <strong>&nbsp;SUBSCRIPTIONS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="heading_2">
                        <strong>Billing and Renewal</strong>
                    </p>
                    <p data-custom-class="body_text">
                        Your subscription will continue and automatically renew unless canceled.
                        You consent to our charging your payment method on a recurring basis
                        without requiring your prior approval for each recurring charge, until
                        such time as you cancel the applicable order. The length of your billing
                        cycle will depend on the type of subscription plan you choose when you
                        subscribed to the Services.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Free Trial</strong>
                    </p>
                    <p data-custom-class="body_text">
                        We offer a 14-day free trial to new users who register with the
                        Services. The account will be charged according to the user's chosen
                        subscription at the end of the free trial.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Cancellation</strong>
                    </p>
                    <p data-custom-class="body_text">
                        You can cancel your subscription at any time by logging into your
                        account. Your cancellation will take effect at the end of the current
                        paid term. If you have any questions or are unsatisfied with our
                        Services, please email us at paul@starmaze.io.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Fee Changes</strong>
                    </p>
                    <p>
                        <span data-custom-class="body_text">
                            We may, from time to time, make changes to the subscription fee and
                            will communicate any price changes to you in accordance with
                            applicable law.
                        </span>
                    </p>
                    <ol start={7}>
                        <li data-custom-class="heading_1">
                            <strong>&nbsp;SOFTWARE</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        We may include software for use in connection with our Services. If such
                        software is accompanied by an end user license agreement ("EULA"), the
                        terms of the EULA will govern your use of the software. If such software
                        is not accompanied by a EULA, then we grant to you a non-exclusive,
                        revocable, personal, and non-transferable license to use such software
                        solely in connection with our services and in accordance with these
                        Legal Terms. Any software and any related documentation is provided "AS
                        IS" without warranty of any kind, either express or implied, including,
                        without limitation, the implied warranties of merchantability, fitness
                        for a particular purpose, or non-infringement. You accept any and all
                        risk arising out of use or performance of any software. You may not
                        reproduce or redistribute any software except in accordance with the
                        EULA or these Legal Terms.
                    </p>
                    <ol start={8}>
                        <li data-custom-class="heading_1">
                            <strong>PROHIBITED ACTIVITIES</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        You may not access or use the Services for any purpose other than that
                        for which we make the Services available. The Services may not be used
                        in connection with any commercial endeavors except those that are
                        specifically endorsed or approved by us.
                    </p>
                    <p data-custom-class="body_text">
                        As a user of the Services, you agree not to:
                    </p>
                    <ul>
                        <li data-custom-class="body_text">
                            Systematically retrieve data or other content from the Services to
                            create or compile, directly or indirectly, a collection, compilation,
                            database, or directory without written permission from us.
                        </li>
                        <li data-custom-class="body_text">
                            Trick, defraud, or mislead us and other users, especially in any
                            attempt to learn sensitive account information such as user passwords.
                        </li>
                        <li data-custom-class="body_text">
                            Circumvent, disable, or otherwise interfere with security-related
                            features of the Services, including features that prevent or restrict
                            the use or copying of any Content or enforce limitations on the use of
                            the Services and/or the Content contained therein.
                        </li>
                        <li data-custom-class="body_text">
                            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
                            Services.
                        </li>
                        <li data-custom-class="body_text">
                            Use any information obtained from the Services in order to harass,
                            abuse, or harm another person.
                        </li>
                        <li data-custom-class="body_text">
                            Make improper use of our support services or submit false reports of
                            abuse or misconduct.
                        </li>
                        <li data-custom-class="body_text">
                            Use the Services in a manner inconsistent with any applicable laws or
                            regulations.
                        </li>
                        <li data-custom-class="body_text">
                            Engage in unauthorized framing of or linking to the Services.
                        </li>
                        <li data-custom-class="body_text">
                            Upload or transmit (or attempt to upload or to transmit) viruses,
                            Trojan horses, or other material, including excessive use of capital
                            letters and spamming (continuous posting of repetitive text), that
                            interferes with any party’s uninterrupted use and enjoyment of the
                            Services or modifies, impairs, disrupts, alters, or interferes with
                            the use, features, functions, operation, or maintenance of the
                            Services.
                        </li>
                        <li data-custom-class="body_text">
                            Engage in any automated use of the system, such as using scripts to
                            send comments or messages, or using any data mining, robots, or
                            similar data gathering and extraction tools.
                        </li>
                        <li data-custom-class="body_text">
                            Delete the copyright or other proprietary rights notice from any
                            Content.
                        </li>
                        <li data-custom-class="body_text">
                            Attempt to impersonate another user or person or use the username of
                            another user.
                        </li>
                        <li data-custom-class="body_text">
                            Upload or transmit (or attempt to upload or to transmit) any material
                            that acts as a passive or active information collection or
                            transmission mechanism, including without limitation, clear graphics
                            interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other
                            similar devices (sometimes referred to as "spyware" or "passive
                            collection mechanisms" or "pcms").
                        </li>
                        <li data-custom-class="body_text">
                            Interfere with, disrupt, or create an undue burden on the Services or
                            the networks or services connected to the Services.
                        </li>
                        <li data-custom-class="body_text">
                            Harass, annoy, intimidate, or threaten any of our employees or agents
                            engaged in providing any portion of the Services to you.
                        </li>
                        <li data-custom-class="body_text">
                            Attempt to bypass any measures of the Services designed to prevent or
                            restrict access to the Services, or any portion of the Services.
                        </li>
                        <li data-custom-class="body_text">
                            Copy or adapt the Services' software, including but not limited to
                            Flash, PHP, HTML, JavaScript, or other code.
                        </li>
                        <li data-custom-class="body_text">
                            Except as permitted by applicable law, decipher, decompile,
                            disassemble, or reverse engineer any of the software comprising or in
                            any way making up a part of the Services.
                        </li>
                        <li data-custom-class="body_text">
                            Except as may be the result of standard search engine or Internet
                            browser usage, use, launch, develop, or distribute any automated
                            system, including without limitation, any spider, robot, cheat
                            utility, scraper, or offline reader that accesses the Services, or use
                            or launch any unauthorized script or other software.
                        </li>
                        <li data-custom-class="body_text">
                            Use a buying agent or purchasing agent to make purchases on the
                            Services.
                        </li>
                        <li data-custom-class="body_text">
                            Make any unauthorized use of the Services, including collecting
                            usernames and/or email addresses of users by electronic or other means
                            for the purpose of sending unsolicited email, or creating user
                            accounts by automated means or under false pretenses.
                        </li>
                        <li data-custom-class="body_text">
                            Use the Services as part of any effort to compete with us or otherwise
                            use the Services and/or the Content for any revenue-generating
                            endeavor or commercial enterprise.
                        </li>
                        <li data-custom-class="body_text">
                            Sell or otherwise transfer your profile.
                        </li>
                        <li data-custom-class="body_text">
                            Use the Services to advertise or offer to sell goods and services.
                        </li>
                    </ul>
                    <ol start={9}>
                        <li data-custom-class="heading_1">
                            <strong>USER GENERATED CONTRIBUTIONS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        The Services may invite you to chat, contribute to, or participate in
                        blogs, message boards, online forums, and other functionality, and may
                        provide you with the opportunity to create, submit, post, display,
                        transmit, perform, publish, distribute, or broadcast content and
                        materials to us or on the Services, including but not limited to text,
                        writings, video, audio, photographs, graphics, comments, suggestions, or
                        personal information or other material (collectively, "Contributions").
                        Contributions may be viewable by other users of the Services and through
                        third-party websites. As such, any Contributions you transmit may be
                        treated as non-confidential and non-proprietary. When you create or make
                        available any Contributions, you thereby represent and warrant that:
                    </p>
                    <ul>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                The creation, distribution, transmission, public display, or
                                performance, and the accessing, downloading, or copying of your
                                Contributions do not and will not infringe the proprietary rights,
                                including but not limited to the copyright, patent, trademark, trade
                                secret, or moral rights of any third party.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                You are the creator and owner of or have the necessary licenses,
                                rights, consents, releases, and permissions to use and to authorize
                                us, the Services, and other users of the Services to use your
                                Contributions in any manner contemplated by the Services and these
                                Legal Terms.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                You have the written consent, release, and/or permission of each and
                                every identifiable individual person in your Contributions to use
                                the name or likeness of each and every such identifiable individual
                                person to enable inclusion and use of your Contributions in any
                                manner contemplated by the Services and these Legal Terms.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions are not false, inaccurate, or misleading.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions are not unsolicited or unauthorized advertising,
                                promotional materials, pyramid schemes, chain letters, spam, mass
                                mailings, or other forms of solicitation.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions are not obscene, lewd, lascivious, filthy,
                                violent, harassing, libelous, slanderous, or otherwise objectionable
                                (as determined by us).
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions do not ridicule, mock, disparage, intimidate, or
                                abuse anyone.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions are not used to harass or threaten (in the legal
                                sense of those terms) any other person and to promote violence
                                against a specific person or class of people.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions do not violate any applicable law, regulation, or
                                rule.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions do not violate the privacy or publicity rights of
                                any third party.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions do not violate any applicable law concerning
                                child pornography, or otherwise intended to protect the health or
                                well-being of minors.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions do not include any offensive comments that are
                                connected to race, national origin, gender, sexual preference, or
                                physical handicap.
                            </span>
                        </li>
                        <li data-custom-class="body_text">
                            <span data-custom-class="body_text">
                                Your Contributions do not otherwise violate, or link to material
                                that violates, any provision of these Legal Terms, or any applicable
                                law or regulation.
                            </span>
                        </li>
                    </ul>
                    <p data-custom-class="body_text">
                        Any use of the Services in violation of the foregoing violates these
                        Legal Terms and may result in, among other things, termination or
                        suspension of your rights to use the Services.
                    </p>
                    <ol start={10}>
                        <li data-custom-class="heading_1">
                            <strong>CONTRIBUTION LICENSE</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        By posting your Contributions to any part of the Services, you
                        automatically grant, and you represent and warrant that you have the
                        right to grant, to us an unrestricted, unlimited, irrevocable,
                        perpetual, non-exclusive, transferable, royalty-free, fully-paid,
                        worldwide right, and license to host, use, copy, reproduce, disclose,
                        sell, resell, publish, broadcast, retitle, archive, store, cache,
                        publicly perform, publicly display, reformat, translate, transmit,
                        excerpt (in whole or in part), and distribute such Contributions
                        (including, without limitation, your image and voice) for any purpose,
                        commercial, advertising, or otherwise, and to prepare derivative works
                        of, or incorporate into other works, such Contributions, and grant and
                        authorize sublicenses of the foregoing. The use and distribution may
                        occur in any media formats and through any media channels.
                    </p>
                    <p data-custom-class="body_text">
                        This license will apply to any form, media, or technology now known or
                        hereafter developed, and includes our use of your name, company name,
                        and franchise name, as applicable, and any of the trademarks, service
                        marks, trade names, logos, and personal and commercial images you
                        provide. You waive all moral rights in your Contributions, and you
                        warrant that moral rights have not otherwise been asserted in your
                        Contributions.
                    </p>
                    <p data-custom-class="body_text">
                        We do not assert any ownership over your Contributions. You retain full
                        ownership of all of your Contributions and any intellectual property
                        rights or other proprietary rights associated with your Contributions.
                        We are not liable for any statements or representations in your
                        Contributions provided by you in any area on the Services. You are
                        solely responsible for your Contributions to the Services and you
                        expressly agree to exonerate us from any and all responsibility and to
                        refrain from any legal action against us regarding your Contributions.
                    </p>
                    <p data-custom-class="body_text">
                        We have the right, in our sole and absolute discretion, (1) to edit,
                        redact, or otherwise change any Contributions; (2) to re-categorize any
                        Contributions to place them in more appropriate locations on the
                        Services; and (3) to pre-screen or delete any Contributions at any time
                        and for any reason, without notice. We have no obligation to monitor
                        your Contributions.
                    </p>
                    <ol start={11}>
                        <li data-custom-class="heading_1">
                            <strong>THIRD-PARTY WEBSITES AND CONTENT</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        The Services may contain (or you may be sent via the Site) links to
                        other websites ("Third-Party Websites") as well as articles,
                        photographs, text, graphics, pictures, designs, music, sound, video,
                        information, applications, software, and other content or items
                        belonging to or originating from third parties ("Third-Party Content").
                        Such Third-Party Websites and Third-Party Content are not investigated,
                        monitored, or checked for accuracy, appropriateness, or completeness by
                        us, and we are not responsible for any Third-Party Websites accessed
                        through the Services or any Third-Party Content posted on, available
                        through, or installed from the Services, including the content,
                        accuracy, offensiveness, opinions, reliability, privacy practices, or
                        other policies of or contained in the Third-Party Websites or the
                        Third-Party Content. Inclusion of, linking to, or permitting the use or
                        installation of any Third-Party Websites or any Third-Party&nbsp;Content
                        does not imply approval or endorsement thereof by us. If you decide to
                        leave the Services and access the Third-Party Websites or to use or
                        install any Third-Party Content, you do so at your own risk, and you
                        should be aware these Legal Terms no longer govern. You should review
                        the applicable terms and policies, including privacy and data gathering
                        practices, of any website to which you navigate from the Services or
                        relating to any applications you use or install from the Services. Any
                        purchases you make through Third-Party Websites will be through other
                        websites and from other companies, and we take no responsibility
                        whatsoever in relation to such purchases which are exclusively between
                        you and the applicable third party. You agree and acknowledge that we do
                        not endorse the products or services offered on Third-Party Websites and
                        you shall hold us blameless from any harm caused by your purchase of
                        such products or services. Additionally, you shall hold us blameless
                        from any losses sustained by you or harm caused to you relating to or
                        resulting in any way from any Third-Party Content or any contact with
                        Third-Party Websites.
                    </p>
                    <ol start={12}>
                        <li data-custom-class="heading_1">
                            <strong>SERVICES MANAGEMENT</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        We reserve the right, but not the obligation, to: (1) monitor the
                        Services for violations of these Legal Terms; (2) take appropriate legal
                        action against anyone who, in our sole discretion, violates the law or
                        these Legal Terms, including without limitation, reporting such user to
                        law enforcement authorities; (3) in our sole discretion and without
                        limitation, refuse, restrict access to, limit the availability of, or
                        disable (to the extent technologically feasible) any of your
                        Contributions or any portion thereof; (4) in our sole discretion and
                        without limitation, notice, or liability, to remove from the Services or
                        otherwise disable all files and content that are excessive in size or
                        are in any way burdensome to our systems; and (5) otherwise manage the
                        Services in a manner designed to protect our rights and property and to
                        facilitate the proper functioning of the Services.
                    </p>
                    <ol start={13}>
                        <li data-custom-class="heading_1">
                            <strong>PRIVACY POLICY</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        We care about data privacy and security. Please review our Privacy
                        Policy:<strong>&nbsp;__________</strong>. By using the Services, you
                        agree to be bound by our Privacy Policy, which is incorporated into
                        these Legal Terms. Please be advised the Services are hosted in
                        __________. If you access the Services from any other region of the
                        world with laws or other requirements governing personal data
                        collection, use, or disclosure that differ from applicable laws in
                        __________, then through your continued use of the Services, you are
                        transferring your data to __________, and you expressly consent to have
                        your data transferred to and processed in __________.
                    </p>
                    <ol start={14}>
                        <li data-custom-class="heading_1">
                            <strong>TERM AND TERMINATION</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        These Legal Terms shall remain in full force and effect while you use
                        the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
                        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
                        OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
                        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
                        OR DELETE YOUR ACCOUNT AND&nbsp;ANY CONTENT OR INFORMATION THAT YOU
                        POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </p>
                    <p data-custom-class="body_text">
                        If we terminate or suspend your account for any reason, you are
                        prohibited from registering and creating a new account under your name,
                        a fake or borrowed name, or the name of any third party, even if you may
                        be acting on behalf of the third party. In addition to terminating or
                        suspending your account, we reserve the right to take appropriate legal
                        action, including without limitation pursuing civil, criminal, and
                        injunctive redress.
                    </p>
                    <ol start={15}>
                        <li data-custom-class="heading_1">
                            <strong>MODIFICATIONS AND INTERRUPTIONS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        We reserve the right to change, modify, or remove the contents of the
                        Services at any time or for any reason at our sole discretion without
                        notice. However, we have no obligation to update any information on our
                        Services. We will not be liable to you or any third party for any
                        modification, price change, suspension, or discontinuance of the
                        Services.
                    </p>
                    <p data-custom-class="body_text">
                        We cannot guarantee the Services will be available at all times. We may
                        experience hardware, software, or other problems or need to perform
                        maintenance related to the Services, resulting in interruptions, delays,
                        or errors. We reserve the right to change, revise, update, suspend,
                        discontinue, or otherwise modify the Services at any time or for any
                        reason without notice to you. You agree that we have no liability
                        whatsoever for any loss, damage, or inconvenience caused by your
                        inability to access or use the Services during any downtime or
                        discontinuance of the Services. Nothing in these Legal Terms will be
                        construed to obligate us to maintain and support the Services or to
                        supply any corrections, updates, or releases in connection therewith.
                    </p>
                    <ol start={16}>
                        <li data-custom-class="heading_1">
                            <strong>GOVERNING LAW</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        These Legal Terms are governed by and interpreted following the laws of
                        France, and the use of the United Nations Convention of Contracts for
                        the International Sales of Goods is expressly excluded. If your habitual
                        residence is in the EU, and you are a consumer, you additionally possess
                        the protection provided to you by obligatory provisions of the law in
                        your country to residence. Starmaze and yourself both agree to submit to
                        the non-exclusive jurisdiction of the courts of __________, which means
                        that you may make a claim to defend your consumer protection rights in
                        regards to these Legal Terms in France, or in the EU country in which
                        you reside.
                    </p>
                    <ol start={17}>
                        <li data-custom-class="heading_1">
                            <strong>DISPUTE RESOLUTION</strong>
                        </li>
                    </ol>
                    <p data-custom-class="heading_2">
                        <strong>Informal Negotiations</strong>
                    </p>
                    <p data-custom-class="body_text">
                        To expedite resolution and control the cost of any dispute, controversy,
                        or claim related to these Legal Terms (each a "Dispute" and
                        collectively, the "Disputes") brought by either you or us (individually,
                        a "Party" and collectively, the "Parties"), the Parties agree to first
                        attempt to negotiate any Dispute (except those Disputes expressly
                        provided below) informally for at least thirty (30) days before
                        initiating arbitration. Such informal negotiations commence upon written
                        notice from one Party to the other Party.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Binding Arbitration</strong>
                    </p>
                    <p data-custom-class="body_text">
                        Any dispute arising from the relationships between the Parties to these
                        Legal Terms shall be determined by one arbitrator who will be chosen in
                        accordance with the Arbitration and Internal Rules of the European Court
                        of Arbitration being part of the European Centre of Arbitration having
                        its seat in Strasbourg, and which are in force at the time the
                        application for arbitration is filed, and of which adoption of this
                        clause constitutes acceptance. The seat of arbitration shall be France.
                        The language of the proceedings shall be __________. Applicable rules of
                        substantive law shall be the law of France.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Restrictions</strong>
                    </p>
                    <p data-custom-class="body_text">
                        The Parties agree that any arbitration shall be limited to the Dispute
                        between the Parties individually. To the full extent permitted by law,
                        (a) no arbitration shall be joined with any other proceeding; (b) there
                        is no right or authority for any Dispute to be arbitrated on a
                        class-action basis or to utilize class action procedures; and (c) there
                        is no right or authority for any Dispute to be brought in a purported
                        representative capacity on behalf of the general public or any other
                        persons.
                    </p>
                    <p data-custom-class="heading_2">
                        <strong>Exceptions to Informal Negotiations and Arbitration</strong>
                    </p>
                    <p data-custom-class="body_text">
                        The Parties agree that the following Disputes are not subject to the
                        above provisions concerning informal negotiations binding arbitration:
                        (a) any Disputes seeking to enforce or protect, or concerning the
                        validity of, any of the intellectual property rights of a Party; (b) any
                        Dispute related to, or arising from, allegations of theft, piracy,
                        invasion of privacy, or unauthorized use; and (c) any claim for
                        injunctive relief. If this provision is found to be illegal or
                        unenforceable, then neither Party will elect to arbitrate any Dispute
                        falling within that portion of this provision found to be illegal or
                        unenforceable and such Dispute shall be decided by a court of competent
                        jurisdiction within the courts listed for jurisdiction above, and the
                        Parties agree to submit to the personal jurisdiction of that court.
                    </p>
                    <ol start={18}>
                        <li data-custom-class="heading_1">
                            <strong>CORRECTIONS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        There may be information on the Services that contains typographical
                        errors, inaccuracies, or omissions, including descriptions, pricing,
                        availability, and various other information. We reserve the right to
                        correct any errors, inaccuracies, or omissions and to change or update
                        the information on the Services at any time, without prior notice.
                    </p>
                    <ol start={19}>
                        <li data-custom-class="heading_1">
                            <strong>&nbsp;DISCLAIMER</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
                        THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                        IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES'
                        CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
                        THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                        (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
                        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
                        FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
                        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                        AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
                        CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
                        VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                        THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
                        OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                        KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
                        OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
                        ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
                        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
                        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
                        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
                        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </p>
                    <ol start={20}>
                        <li data-custom-class="heading_1">
                            <strong>LIMITATIONS OF LIABILITY</strong>
                        </li>
                    </ol>
                    <p>
                        <span data-custom-class="body_text">
                            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
                            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
                            USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
                            OF SUCH DAMAGES.
                        </span>
                        <span data-custom-class="body_text">
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
                            OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF
                            ANY, BY YOU TO US&nbsp;DURING THE six (6) mONTH PERIOD PRIOR TO ANY
                            CAUSE OF ACTION ARISING.
                        </span>{" "}
                        <span data-custom-class="body_text">
                            CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS
                            ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                            DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                            DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                            ADDITIONAL RIGHTS.
                        </span>
                    </p>
                    <ol start={21}>
                        <li data-custom-class="heading_1">
                            <strong>INDEMNIFICATION</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        You agree to defend, indemnify, and hold us harmless, including our
                        subsidiaries, affiliates, and all of our respective officers, agents,
                        partners, and employees, from and against any loss, damage, liability,
                        claim, or demand, including reasonable attorneys’ fees and expenses,
                        made by any third party due to or arising out of: (1) your
                        Contributions;&nbsp;(2) use of the Services; (3) breach of these Legal
                        Terms; (4) any breach of your representations and warranties set forth
                        in these Legal Terms; (5) your violation of the rights of a third party,
                        including but not limited to intellectual property rights; or (6) any
                        overt harmful act toward any other user of the Services with whom you
                        connected via the Services. Notwithstanding the foregoing, we reserve
                        the right, at your expense, to assume the exclusive defense and control
                        of any matter for which you are required to indemnify us, and you agree
                        to cooperate, at your expense, with our defense of such claims. We will
                        use reasonable efforts to notify you of any such claim, action, or
                        proceeding which is subject to this indemnification upon becoming aware
                        of it.
                    </p>
                    <ol start={22}>
                        <li data-custom-class="heading_1">
                            <strong>USER DATA</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        We will maintain certain data that you transmit to the Services for the
                        purpose of managing the performance of the Services, as well as data
                        relating to your use of the Services. Although we perform regular
                        routine backups of data, you are solely responsible for all data that
                        you transmit or that relates to any activity you have undertaken using
                        the Services. You agree that we shall have no liability to you for any
                        loss or corruption of any such data, and you hereby waive any right of
                        action against us arising from any such loss or corruption of such data.
                    </p>
                    <ol start={23}>
                        <li data-custom-class="heading_1">
                            <strong>
                                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                            </strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        Visiting the Services, sending us emails, and completing online forms
                        constitute electronic communications. You consent to receive electronic
                        communications, and you agree that all agreements, notices, disclosures,
                        and other communications we provide to you electronically, via email and
                        on the Services, satisfy any legal requirement that such communication
                        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
                        US OR VIA THE SERVICES. You hereby waive any rights or requirements
                        under any statutes, regulations, rules, ordinances, or other laws in any
                        jurisdiction which require an original signature or delivery or
                        retention of non-electronic records, or to payments or the granting of
                        credits by any means other than electronic means.
                    </p>
                    <ol start={24}>
                        <li data-custom-class="heading_1">
                            <strong>CALIFORNIA USERS AND RESIDENTS</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        If any complaint with us is not satisfactorily resolved, you can contact
                        the Complaint Assistance Unit of the Division of Consumer Services of
                        the California Department of Consumer Affairs in writing at 1625 North
                        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
                        at (800) 952-5210 or (916) 445-1254.
                    </p>
                    <ol start={25}>
                        <li data-custom-class="heading_1">
                            <strong>MISCELLANEOUS&nbsp;</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        These Legal Terms and any policies or operating rules posted by us on
                        the Services or in respect to the Services constitute the entire
                        agreement and understanding between you and us. Our failure to exercise
                        or enforce any right or provision of these Legal Terms shall not operate
                        as a waiver of such right or provision. These Legal Terms operate to the
                        fullest extent permissible by law. We may assign any or all of our
                        rights and obligations to others at any time. We shall not be
                        responsible or liable for any loss, damage, delay, or failure to act
                        caused by any cause beyond our reasonable control. If any provision or
                        part of a provision of these Legal Terms is determined to be unlawful,
                        void, or unenforceable, that provision or part of the provision is
                        deemed severable from these Legal Terms and does not affect the validity
                        and enforceability of any remaining provisions. There is no joint
                        venture, partnership, employment or agency relationship created between
                        you and us as a result of these Legal Terms or use of the Services. You
                        agree that these Legal Terms will not be construed against us by virtue
                        of having drafted them. You hereby waive any and all defenses you may
                        have based on the electronic form of these Legal Terms and the lack of
                        signing by the parties hereto to execute these Legal Terms.
                    </p>
                    <ol start={26}>
                        <li data-custom-class="heading_1">
                            <strong>CONTACT US</strong>
                        </li>
                    </ol>
                    <p data-custom-class="body_text">
                        In order to resolve a complaint regarding the Services or to receive
                        further information regarding use of the Services, please contact us at:
                    </p>
                    <p data-custom-class="body_text">
                        <strong>Starmaze</strong>
                    </p>
                    <p data-custom-class="body_text">
                        <strong>
                            <strong>__________</strong>
                        </strong>
                    </p>
                    <p data-custom-class="body_text">
                        <strong>
                            <strong>France</strong>
                        </strong>
                    </p>
                    <p data-custom-class="body_text">
                        <strong>
                            <strong>paul@starmaze.io</strong>
                        </strong>
                    </p>
                    <p>
                        ul {"{"} list-style-type: square; {"}"} ul&gt;li&gt;ul {"{"}{" "}
                        list-style-type: circle; {"}"} ul&gt;li&gt;ul&gt;li&gt;ul {"{"}{" "}
                        list-style-type: square; {"}"} ol li {"{"} font-family: Arial; {"}"}
                    </p>
                    <p>
                        These terms of use were created using Termly's{" "}
                        <a href="https://termly.io/products/terms-and-conditions-generator/">
                            Terms and Conditions Generator
                        </a>
                        .&nbsp;
                    </p>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div className="MsoNormal" id="agreement" style={{ lineHeight: "1.5" }}>
                        <a name="_6aa3gkhykvst" />
                    </div>
                </div>
                <div align="center" style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            We are Starmaze, doing business as Starmaze ("<strong>Company</strong>
                            ," "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>
                            ")
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            msoFareastFontFamily: "Calibri",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        .
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            We operate the website{" "}
                            <span style={{ color: "rgb(0, 58, 250)" }}>
                                <a
                                    href="https://starmaze.io/astrokeeper"
                                    target="_blank"
                                    data-custom-class="link"
                                >
                                    https://starmaze.io/astrokeeper
                                </a>
                            </span>{" "}
                            (the "<strong>Site</strong>"), as well as any other related products
                            and services that refer or link to these legal terms (the "
                            <strong>Legal Terms</strong>") (collectively, the "
                            <strong>Services</strong>").
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            You can contact us by email at paul@starmaze.io or by mail to
                            __________, __________,&nbsp;France.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            These Legal Terms constitute a legally binding agreement made between
                            you, whether personally or on behalf of an entity ("
                            <strong>you</strong>"), and Starmaze, concerning your access to and
                            use of the Services. You agree that by accessing the Services, you
                            have read, understood, and agreed to be bound by all of these Legal
                            Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
                            EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
                            USE IMMEDIATELY.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            Supplemental terms and conditions or documents that may be posted on
                            the Services from time to time are hereby expressly incorporated
                            herein by reference. We reserve the right, in our sole discretion, to
                            make changes or modifications to these Legal Terms from time to time.
                            We will alert you about any changes by updating the "Last updated"
                            date of these Legal Terms, and you waive any right to receive specific
                            notice of each such change. It is your responsibility to periodically
                            review these Legal Terms to stay informed of updates. You will be
                            subject to, and will be deemed to have been made aware of and to have
                            accepted, the changes in any revised Legal Terms by your continued use
                            of the Services after the date such revised Legal Terms are posted.
                        </span>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                                All users who are minors in the jurisdiction in which they reside
                                (generally under the age of 18) must have the permission of, and be
                                directly supervised by, their parent or guardian to use the
                                Services. If you are a minor, you must have your parent or guardian
                                read and agree to these Legal Terms prior to you using the Services.
                            </span>
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        We recommend that you print a copy of these Legal Terms for your
                        records.
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>TABLE OF CONTENTS</strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a href="#services">
                            <span data-custom-class="link">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">1. OUR SERVICES</span>
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#ip">
                            <span style={{ color: "rgb(0, 58, 250)" }}>
                                <span data-custom-class="body_text">
                                    2. INTELLECTUAL PROPERTY RIGHTS
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#userreps">
                            <span style={{ color: "rbg(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">3. USER REPRESENTATIONS</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#userreg">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">4. USER REGISTRATION</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#purchases">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">5. PURCHASES AND PAYMENT</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#subscriptions">
                            <span data-custom-class="body_text">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    6. SUBSCRIPTIONS
                                </span>
                            </span>
                        </a>
                        <span style={{ fontSize: 15 }}>
                            <br />
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#software">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">7. SOFTWARE</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#prohibited">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">8. PROHIBITED ACTIVITIES</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#ugc">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    9. USER GENERATED CONTRIBUTIONS
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#license">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">10. CONTRIBUTION LICENSE</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#thirdparty">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    11. THIRD-PARTY WEBSITES AND CONTENT
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#sitemanage">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">12. SERVICES MANAGEMENT</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#ppyes">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">13. PRIVACY POLICY</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#terms">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">14. TERM AND TERMINATION</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#modifications">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    15. MODIFICATIONS AND INTERRUPTIONS
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#law">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">16. GOVERNING LAW</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#disputes">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">17. DISPUTE RESOLUTION</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#corrections">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">18. CORRECTIONS</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#disclaimer">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">19. DISCLAIMER</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#liability">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    20. LIMITATIONS OF LIABILITY
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#indemnification">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">21. INDEMNIFICATION</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#userdata">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">22. USER DATA</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#electronic">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#california">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    24. CALIFORNIA USERS AND RESIDENTS
                                </span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#misc">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">25. MISCELLANEOUS</span>
                            </span>
                        </a>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#contact">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                <span data-custom-class="body_text">26. CONTACT US</span>
                            </span>
                        </a>
                    </div>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_b6y29mp52qvx" />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="services"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>
                            <span style={{ fontSize: 19 }}>1. OUR SERVICES</span>
                        </strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            The information provided when using the Services is not intended for
                            distribution to or use by any person or entity in any jurisdiction or
                            country where such distribution or use would be contrary to law or
                            regulation or which would subject us to any registration requirement
                            within such jurisdiction or country. Accordingly, those persons who
                            choose to access the Services from other locations do so on their own
                            initiative and are solely responsible for compliance with local laws,
                            if and to the extent local laws are applicable.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            The Services are not tailored to comply with industry-specific
                            regulations (Health Insurance Portability and Accountability Act
                            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
                            so if your interactions would be subjected to such laws, you may not
                            use the Services. You may not use the Services in a way that would
                            violate the Gramm-Leach-Bliley Act (GLBA).
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                </div>
                <div
                    align="center"
                    data-custom-class="heading_1"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                    <strong>
                        <span id="ip" style={{ fontSize: 19 }}>
                            2. INTELLECTUAL PROPERTY RIGHTS
                        </span>
                    </strong>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>Our intellectual property</strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            We are the owner or the licensee of all intellectual property rights
                            in our Services, including all source code, databases, functionality,
                            software, website designs, audio, video, text, photographs, and
                            graphics in the Services (collectively, the "Content"), as well as the
                            trademarks, service marks, and logos contained therein (the "Marks").
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            Our Content and Marks are protected by copyright and trademark laws
                            (and various other intellectual property rights and unfair competition
                            laws) and treaties in the United States and around the world.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            The Content and Marks are provided in or through the Services "AS IS"
                            for your personal, non-commercial use or internal business purpose
                            only.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>Your use of our Services</strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            Subject to your compliance with these Legal Terms, including the "
                        </span>
                        <a data-custom-class="link" href="#prohibited">
                            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                PROHIBITED ACTIVITIES
                            </span>
                        </a>
                        <span style={{ fontSize: 15 }}>
                            " section below, we grant you a non-exclusive, non-transferable,
                            revocable license to:
                        </span>
                    </div>
                    <ul>
                        <li
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>access the Services; and</span>
                        </li>
                        <li
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                download or print a copy of any portion of the Content to which you
                                have properly gained access.
                            </span>
                        </li>
                    </ul>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            solely for your personal, non-commercial use or internal business
                            purpose.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            Except as set out in this section or elsewhere in our Legal Terms, no
                            part of the Services and no Content or Marks may be copied,
                            reproduced, aggregated, republished, uploaded, posted, publicly
                            displayed, encoded, translated, transmitted, distributed, sold,
                            licensed, or otherwise exploited for any commercial purpose
                            whatsoever, without our express prior written permission.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            If you wish to make any use of the Services, Content, or Marks other
                            than as set out in this section or elsewhere in our Legal Terms,
                            please address your request to: paul@starmaze.io. If we ever grant you
                            the permission to post, reproduce, or publicly display any part of our
                            Services or Content, you must identify us as the owners or licensors
                            of the Services, Content, or Marks and ensure that any copyright or
                            proprietary notice appears or is visible on posting, reproducing, or
                            displaying our Content.
                        </span>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            We reserve all rights not expressly granted to you in and to the
                            Services, Content, and Marks.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            Any breach of these Intellectual Property Rights will constitute a
                            material breach of our Legal Terms and your right to use our Services
                            will terminate immediately.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            <strong>Your submissions and contributions</strong>
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            Please review this section and the "
                            <a data-custom-class="link" href="#prohibited">
                                <span style={{ color: "rgb(0, 58, 250)" }}>
                                    PROHIBITED ACTIVITIES
                                </span>
                            </a>
                            " section carefully prior to using our Services to understand the (a)
                            rights you give us and (b) obligations you have when you post or
                            upload any content through the Services.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            <strong>Submissions:</strong> By directly sending us any question,
                            comment, suggestion, idea, feedback, or other information about the
                            Services ("Submissions"), you agree to assign to us all intellectual
                            property rights in such Submission. You agree that we shall own this
                            Submission and be entitled to its unrestricted use and dissemination
                            for any lawful purpose, commercial or otherwise, without
                            acknowledgment or compensation to you.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            <strong>Contributions:</strong> The Services may invite you to chat,
                            contribute to, or participate in blogs, message boards, online forums,
                            and other functionality during which you may create, submit, post,
                            display, transmit, publish, distribute, or broadcast content and
                            materials to us or through the Services, including but not limited to
                            text, writings, video, audio, photographs, music, graphics, comments,
                            reviews, rating suggestions, personal information, or other material
                            ("Contributions"). Any Submission that is publicly posted shall also
                            be treated as a Contribution.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            You understand that Contributions may be viewable by other users of
                            the Services and possibly through third-party websites.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            <strong>
                                When you post Contributions, you grant us a license (including use
                                of your name, trademarks, and logos):&nbsp;
                            </strong>
                            By posting any Contributions, you grant us an unrestricted, unlimited,
                            irrevocable, perpetual, non-exclusive, transferable, royalty-free,
                            fully-paid, worldwide right, and license to: use, copy, reproduce,
                            distribute, sell, resell, publish, broadcast, retitle, store, publicly
                            perform, publicly display, reformat, translate, excerpt (in whole or
                            in part), and exploit your Contributions (including, without
                            limitation, your image, name, and voice) for any purpose, commercial,
                            advertising, or otherwise, to prepare derivative works of, or
                            incorporate into other works, your Contributions, and to sublicense
                            the licenses granted in this section. Our use and distribution may
                            occur in any media formats and through any media channels.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            This license includes our use of your name, company name, and
                            franchise name, as applicable, and any of the trademarks, service
                            marks, trade names, logos, and personal and commercial images you
                            provide.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            <strong>You are responsible for what you post or upload:</strong> By
                            sending us Submissions and/or posting Contributions through any part
                            of the Services or making Contributions accessible through the
                            Services by linking your account through the Services to any of your
                            social networking accounts, you:
                        </span>
                    </div>
                    <ul>
                        <li
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                confirm that you have read and agree with our "
                            </span>
                            <a data-custom-class="link" href="#prohibited">
                                <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                                    PROHIBITED ACTIVITIES
                                </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
                                " and will not post, send, publish, upload, or transmit through the
                                Services any Submission nor post any Contribution that is illegal,
                                harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
                                discriminatory, threatening to any person or group, sexually
                                explicit, false, inaccurate, deceitful, or misleading;
                            </span>
                        </li>
                        <li
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                to the extent permissible by applicable law, waive any and all moral
                                rights to any such Submission and/or Contribution;
                            </span>
                        </li>
                        <li
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                warrant that any such Submission and/or Contributions are original
                                to you or that you have the necessary rights and licenses to submit
                                such Submissions and/or Contributions and that you have full
                                authority to grant us the above-mentioned rights in relation to your
                                Submissions and/or Contributions; and
                            </span>
                        </li>
                        <li
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                warrant and represent that your Submissions and/or Contributions do
                                not constitute confidential information.
                            </span>
                        </li>
                    </ul>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        You are solely responsible for your Submissions and/or Contributions and
                        you expressly agree to reimburse us for any and all losses that we may
                        suffer because of your breach of (a) this section, (b) any third party’s
                        intellectual property rights, or (c) applicable law.
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>We may remove or edit your Content:</strong> Although we have no
                        obligation to monitor any Contributions, we shall have the right to
                        remove or edit any Contributions at any time without notice if in our
                        reasonable opinion we consider such Contributions harmful or in breach
                        of these Legal Terms. If we remove or edit any such Contributions, we
                        may also suspend or disable your account and report you to the
                        authorities.
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="userreps"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_5hg7kgyv9l8z" />
                        <strong>
                            <span
                                style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                            >
                                <strong>
                                    <span
                                        style={{
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            fontSize: 19
                                        }}
                                    >
                                        <strong>
                                            <span
                                                style={{
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    fontSize: 19
                                                }}
                                            >
                                                <strong>
                                                    <span
                                                        style={{
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            fontSize: 19
                                                        }}
                                                    >
                                                        3.
                                                    </span>
                                                </strong>
                                            </span>
                                            &nbsp;
                                        </strong>
                                    </span>
                                </strong>
                                USER REPRESENTATIONS
                            </span>
                        </strong>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            By using the Services, you represent and warrant that:
                        </span>{" "}
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>(</span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            1
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) all registration information you submit will be true, accurate,
                            current, and complete; (
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            2
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) you will maintain the accuracy of such information and promptly
                            update such registration information as necessary;
                        </span>{" "}
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>(</span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            3
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) you have the legal capacity and you agree to comply with these Legal
                            Terms;
                        </span>{" "}
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>(</span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            4
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) you are not a minor in the jurisdiction in which you reside, or if a
                            minor, you have received parental permission to use the Services; (
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            5
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) you will not access the Services through automated or non-human
                            means, whether through a bot, script or otherwise; (
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            6
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) you will not use the Services for any illegal or unauthorized
                            purpose; and (
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                            7
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                            ) your use of the Services will not violate any applicable law or
                            regulation.
                        </span>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: "115%" }}
                    >
                        <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                            <div
                                className="MsoNormal"
                                data-custom-class="body_text"
                                style={{ lineHeight: "1.5", textAlign: "left" }}
                            >
                                <span
                                    style={{
                                        fontSize: "11pt",
                                        lineHeight: "16.8667px",
                                        color: "rgb(89, 89, 89)"
                                    }}
                                >
                                    If you provide any information that is untrue, inaccurate, not
                                    current, or incomplete, we have the right to suspend or terminate
                                    your account and refuse any and all current or future use of the
                                    Services (or any portion thereof).
                                </span>
                            </div>
                            <div
                                className="MsoNormal"
                                style={{ lineHeight: "1.1", textAlign: "left" }}
                            >
                                <br />
                            </div>
                            <div
                                className="MsoNormal"
                                style={{ lineHeight: "1.5", textAlign: "left" }}
                            >
                                <br />
                            </div>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <div
                                className="MsoNormal"
                                data-custom-class="heading_1"
                                id="userreg"
                                style={{ lineHeight: "1.5", textAlign: "left" }}
                            >
                                <strong>
                                    <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                        <strong>
                                            <span
                                                style={{
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    fontSize: 19
                                                }}
                                            >
                                                <strong>
                                                    <span
                                                        style={{
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            fontSize: 19
                                                        }}
                                                    >
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight: "115%",
                                                                    fontFamily: "Arial",
                                                                    fontSize: 19
                                                                }}
                                                            >
                                                                4.
                                                            </span>
                                                        </strong>
                                                    </span>
                                                    &nbsp;
                                                </strong>
                                            </span>
                                        </strong>
                                        USER REGISTRATION
                                    </span>
                                </strong>
                            </div>
                        </div>
                        <div
                            className="MsoNormal"
                            style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <div
                                className="MsoNormal"
                                data-custom-class="body_text"
                                style={{ textAlign: "left", lineHeight: "1.5" }}
                            >
                                <span
                                    style={{
                                        fontSize: "11pt",
                                        lineHeight: "16.8667px",
                                        color: "rgb(89, 89, 89)"
                                    }}
                                >
                                    You may be required to register to use the Services. You agree to
                                    keep your password confidential and will be responsible for all
                                    use of your account and password. We reserve the right to remove,
                                    reclaim, or change a username you select if we determine, in our
                                    sole discretion, that such username is inappropriate, obscene, or
                                    otherwise objectionable.
                                </span>
                            </div>
                        </div>
                        <div
                            className="MsoNormal"
                            style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                            <br />
                        </div>
                    </div>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="purchases"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_ynub0jdx8pob" />
                        <strong>
                            <span
                                style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                            >
                                <strong>
                                    <span
                                        style={{
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            fontSize: 19
                                        }}
                                    >
                                        <strong>
                                            <span
                                                style={{
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    fontSize: 19
                                                }}
                                            >
                                                <strong>
                                                    <span
                                                        style={{
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            fontSize: 19
                                                        }}
                                                    >
                                                        5.
                                                    </span>
                                                </strong>
                                            </span>
                                        </strong>
                                    </span>
                                    &nbsp;
                                </strong>
                                PURCHASES AND PAYMENT
                            </span>
                        </strong>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            We accept the following forms of payment:
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: "115%" }}
                    >
                        <div className="MsoNormal" style={{ textAlign: "left", lineHeight: 1 }}>
                            <br />
                        </div>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5", marginLeft: 20 }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            - &nbsp;Visa
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5", marginLeft: 20 }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            - &nbsp;Mastercard
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5", marginLeft: 20 }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            - &nbsp;American Express
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5", marginLeft: 20 }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            - &nbsp;PayPal
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5", marginLeft: 20 }}
                    >
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            <br />
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            You agree to provide current, complete, and accurate purchase and
                            account information for all purchases made via the Services. You
                            further agree to promptly update account and payment information,
                            including email address, payment method, and payment card expiration
                            date, so that we can complete your transactions and contact you as
                            needed. Sales tax will be added to the price of purchases as deemed
                            required by us. We may change prices at any time. All payments shall
                            be&nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: 15,
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            in Euros.
                        </span>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            You agree to pay all charges at the prices then in effect for your
                            purchases and any applicable shipping fees, and you authorize us to
                            charge your chosen payment provider for any such amounts upon placing
                            your order. We reserve the right to correct any errors or mistakes in
                            pricing, even if we have already requested or received payment.
                        </span>
                    </div>
                </div>
                <div align="center" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            We reserve the right to refuse any order placed through the Services.
                            We may, in our sole discretion, limit or cancel quantities purchased
                            per person, per household, or per order. These restrictions may
                            include orders placed by or under the same customer account, the same
                            payment method, and/or orders that use the same billing or shipping
                            address. We reserve the right to limit or prohibit orders that, in our
                            sole judgment, appear to be placed by dealers, resellers, or
                            distributors.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="subscriptions"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>
                            <span style={{ fontSize: 19 }}>6. SUBSCRIPTIONS</span>
                        </strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>
                            <span style={{ fontSize: 15 }}>Billing and Renewal</span>
                        </strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <br />
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            Your subscription will continue and automatically renew unless
                            canceled. You consent to our charging your payment method on a
                            recurring basis without requiring your prior approval for each
                            recurring charge, until such time as you cancel the applicable order.
                            The length of your billing cycle will depend on the type of
                            subscription plan you choose when you subscribed to the Services.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <br />
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            <strong>Free Trial</strong>
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <br />
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            We offer a 14-day free trial to new users who register with the
                            Services. The account will be charged according to the user's chosen
                            subscription at the end of the free trial.
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <br />
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            <strong>Cancellation</strong>
                        </span>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        You can cancel your subscription at any time by logging into your
                        account. Your cancellation will take effect at the end of the current
                        paid term. If you have any questions or are unsatisfied with our
                        Services, please email us at paul@starmaze.io.
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_2"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>
                            <span style={{ fontSize: 15 }}>Fee Changes</span>
                        </strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <br />
                        </span>
                    </div>
                    <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                            We may, from time to time, make changes to the subscription fee and
                            will communicate any price changes to you in accordance with
                            applicable law.
                        </span>
                    </span>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="software"
                        style={{ lineHeight: "1.5" }}
                    >
                        <strong>
                            <span style={{ fontSize: 19 }}>7. SOFTWARE</span>
                        </strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ fontSize: 15 }}>
                            We may include software for use in connection with our Services. If
                            such software is accompanied by an end user license agreement
                            ("EULA"), the terms of the EULA will govern your use of the software.
                            If such software is not accompanied by a EULA, then we grant to you a
                            non-exclusive, revocable, personal, and non-transferable license to
                            use such software solely in connection with our services and in
                            accordance with these Legal Terms. Any software and any related
                            documentation is provided "AS IS" without warranty of any kind, either
                            express or implied, including, without limitation, the implied
                            warranties of merchantability, fitness for a particular purpose, or
                            non-infringement. You accept any and all risk arising out of use or
                            performance of any software. You may not reproduce or redistribute any
                            software except in accordance with the EULA or these Legal Terms.
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            <div
                                className="MsoNormal"
                                style={{ fontSize: 15, lineHeight: "1.5" }}
                            >
                                <br />
                            </div>
                        </span>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="prohibited"
                            style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            8.
                                                        </span>
                                                    </strong>
                                                </span>
                                                &nbsp;
                                            </strong>
                                        </span>
                                    </strong>
                                    PROHIBITED ACTIVITIES
                                </span>
                            </strong>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", textAlign: "left" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                You may not access or use the Services for any purpose other than
                                that for which we make the Services available. The Services may not
                                be used in connection with any commercial endeavors except those
                                that are specifically endorsed or approved by us.
                            </span>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                    >
                        <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                            <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                                <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                                    <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            As a user of the Services, you agree not to:
                                        </span>
                                    </div>
                                </div>
                                <ul>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 15,
                                                    fontStyle: "normal",
                                                    fontVariantLigatures: "normal",
                                                    fontVariantCaps: "normal",
                                                    fontWeight: 400,
                                                    letterSpacing: "normal",
                                                    orphans: 2,
                                                    textAlign: "justify",
                                                    textIndent: "-29.4px",
                                                    textTransform: "none",
                                                    whiteSpace: "normal",
                                                    widows: 2,
                                                    wordSpacing: 0,
                                                    WebkitTextStrokeWidth: 0,
                                                    backgroundColor: "rgb(255, 255, 255)",
                                                    textDecorationStyle: "initial",
                                                    textDecorationColor: "initial",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                Systematically retrieve data or other content from the
                                                Services to create or compile, directly or indirectly, a
                                                collection, compilation, database, or directory without
                                                written permission from us.
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Trick, defraud, or mislead us and other users,
                                                            especially in any attempt to learn sensitive account
                                                            information such as user passwords.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Circumvent, disable, or otherwise interfere with
                                                            security-related features of the Services, including
                                                            features that prevent or restrict the use or copying
                                                            of any Content or enforce limitations on the use of
                                                            the Services and/or the Content contained therein.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Disparage, tarnish, or otherwise harm, in our opinion,
                                                            us and/or the Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Use any information obtained from the Services in
                                                            order to harass, abuse, or harm another person.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Make improper use of our support services or submit
                                                            false reports of abuse or misconduct.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Use the Services in a manner inconsistent with any
                                                            applicable laws or regulations.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Engage in unauthorized framing of or linking to the
                                                            Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Upload or transmit (or attempt to upload or to
                                                            transmit) viruses, Trojan horses, or other material,
                                                            including excessive use of capital letters and
                                                            spamming (continuous posting of repetitive text), that
                                                            interferes with any party’s uninterrupted use and
                                                            enjoyment of the Services or modifies, impairs,
                                                            disrupts, alters, or interferes with the use,
                                                            features, functions, operation, or maintenance of the
                                                            Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Engage in any automated use of the system, such as
                                                            using scripts to send comments or messages, or using
                                                            any data mining, robots, or similar data gathering and
                                                            extraction tools.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Delete the copyright or other proprietary rights
                                                            notice from any Content.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Attempt to impersonate another user or person or use
                                                            the username of another user.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Upload or transmit (or attempt to upload or to
                                                            transmit) any material that acts as a passive or
                                                            active information collection or transmission
                                                            mechanism, including without limitation, clear
                                                            graphics interchange formats ("gifs"), 1×1 pixels, web
                                                            bugs, cookies, or other similar devices (sometimes
                                                            referred to as "spyware" or "passive collection
                                                            mechanisms" or "pcms").
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Interfere with, disrupt, or create an undue burden on
                                                            the Services or the networks or services connected to
                                                            the Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Harass, annoy, intimidate, or threaten any of our
                                                            employees or agents engaged in providing any portion
                                                            of the Services to you.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Attempt to bypass any measures of the Services
                                                            designed to prevent or restrict access to the
                                                            Services, or any portion of the Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Copy or adapt the Services' software, including but
                                                            not limited to Flash, PHP, HTML, JavaScript, or other
                                                            code.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Except as permitted by applicable law, decipher,
                                                            decompile, disassemble, or reverse engineer any of the
                                                            software comprising or in any way making up a part of
                                                            the Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Except as may be the result of standard search engine
                                                            or Internet browser usage, use, launch, develop, or
                                                            distribute any automated system, including without
                                                            limitation, any spider, robot, cheat utility, scraper,
                                                            or offline reader that accesses the Services, or use
                                                            or launch any unauthorized script or other software.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Use a buying agent or purchasing agent to make
                                                            purchases on the Services.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Make any unauthorized use of the Services, including
                                                            collecting usernames and/or email addresses of users
                                                            by electronic or other means for the purpose of
                                                            sending unsolicited email, or creating user accounts
                                                            by automated means or under false pretenses.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: 15 }}>
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        fontStyle: "normal",
                                                        fontVariantLigatures: "normal",
                                                        fontVariantCaps: "normal",
                                                        fontWeight: 400,
                                                        letterSpacing: "normal",
                                                        orphans: 2,
                                                        textAlign: "justify",
                                                        textIndent: "-29.4px",
                                                        textTransform: "none",
                                                        whiteSpace: "normal",
                                                        widows: 2,
                                                        wordSpacing: 0,
                                                        WebkitTextStrokeWidth: 0,
                                                        backgroundColor: "rgb(255, 255, 255)",
                                                        textDecorationStyle: "initial",
                                                        textDecorationColor: "initial",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            lineHeight: "16.8667px",
                                                            color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily: "sans-serif",
                                                                fontStyle: "normal",
                                                                fontVariantLigatures: "normal",
                                                                fontVariantCaps: "normal",
                                                                fontWeight: 400,
                                                                letterSpacing: "normal",
                                                                orphans: 2,
                                                                textAlign: "justify",
                                                                textIndent: "-29.4px",
                                                                textTransform: "none",
                                                                whiteSpace: "normal",
                                                                widows: 2,
                                                                wordSpacing: 0,
                                                                WebkitTextStrokeWidth: 0,
                                                                backgroundColor: "rgb(255, 255, 255)",
                                                                textDecorationStyle: "initial",
                                                                textDecorationColor: "initial",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            Use the Services as part of any effort to compete with
                                                            us or otherwise use the Services and/or the Content
                                                            for any revenue-generating endeavor or commercial
                                                            enterprise.
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            Sell or otherwise transfer your profile.
                                        </span>
                                    </li>
                                    <li
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5", textAlign: "left" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            Use the Services to advertise or offer to sell goods and
                                            services.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="MsoNormal"
                                style={{ lineHeight: "1.5", textAlign: "left" }}
                            >
                                <br />
                            </div>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                            <div className="MsoNormal" style={{ lineHeight: 1 }}>
                                <div
                                    className="MsoNormal"
                                    data-custom-class="heading_1"
                                    id="ugc"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <strong>
                                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                            <strong>
                                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        lineHeight: "115%",
                                                                        fontFamily: "Arial",
                                                                        fontSize: 19
                                                                    }}
                                                                >
                                                                    9.
                                                                </span>
                                                            </strong>
                                                        </span>
                                                    </strong>
                                                </span>
                                                &nbsp;
                                            </strong>
                                            USER GENERATED CONTRIBUTIONS
                                        </span>
                                    </strong>
                                </div>
                            </div>
                            <div
                                className="MsoNormal"
                                style={{ lineHeight: "1.5", textAlign: "left" }}
                            >
                                <br />
                            </div>
                            <div className="MsoNormal" style={{ lineHeight: 1 }}>
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11pt",
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)"
                                        }}
                                    >
                                        The Services may invite you to chat, contribute to, or
                                        participate in blogs, message boards, online forums, and other
                                        functionality, and may provide you with the opportunity to
                                        create, submit, post, display, transmit, perform, publish,
                                        distribute, or broadcast content and materials to us or on the
                                        Services, including but not limited to text, writings, video,
                                        audio, photographs, graphics, comments, suggestions, or personal
                                        information or other material (collectively, "Contributions").
                                        Contributions may be viewable by other users of the Services and
                                        through third-party websites. As such, any Contributions you
                                        transmit may be treated as non-confidential and non-proprietary.
                                        When you create or make available any Contributions, you thereby
                                        represent and warrant that:
                                    </span>
                                </div>
                            </div>
                            <ul style={{ fontSize: "medium", textAlign: "left" }}>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                The creation, distribution, transmission, public display, or
                                                performance, and the accessing, downloading, or copying of
                                                your Contributions do not and will not infringe the
                                                proprietary rights, including but not limited to the
                                                copyright, patent, trademark, trade secret, or moral rights
                                                of any third party.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                You are the creator and owner of or have the necessary
                                                licenses, rights, consents, releases, and permissions to use
                                                and to authorize us, the Services, and other users of the
                                                Services to use your Contributions in any manner
                                                contemplated by the Services and these Legal Terms.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                You have the written consent, release, and/or permission of
                                                each and every identifiable individual person in your
                                                Contributions to use the name or likeness of each and every
                                                such identifiable individual person to enable inclusion and
                                                use of your Contributions in any manner contemplated by the
                                                Services and these Legal Terms.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions are not false, inaccurate, or misleading.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions are not unsolicited or unauthorized
                                                advertising, promotional materials, pyramid schemes, chain
                                                letters, spam, mass mailings, or other forms of
                                                solicitation.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions are not obscene, lewd, lascivious,
                                                filthy, violent, harassing, libelous, slanderous, or
                                                otherwise objectionable (as determined by us).
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions do not ridicule, mock, disparage,
                                                intimidate, or abuse anyone.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions are not used to harass or threaten (in
                                                the legal sense of those terms) any other person and to
                                                promote violence against a specific person or class of
                                                people.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions do not violate any applicable law,
                                                regulation, or rule.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions do not violate the privacy or publicity
                                                rights of any third party.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions do not violate any applicable law
                                                concerning child pornography, or otherwise intended to
                                                protect the health or well-being of minors.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions do not include any offensive comments
                                                that are connected to race, national origin, gender, sexual
                                                preference, or physical handicap.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 14 }}>
                                            <span data-custom-class="body_text">
                                                Your Contributions do not otherwise violate, or link to
                                                material that violates, any provision of these Legal Terms,
                                                or any applicable law or regulation.
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: "1.5" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                Any use of the Services in violation of the foregoing violates these
                                Legal Terms and may result in, among other things, termination or
                                suspension of your rights to use the Services.
                            </span>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="license"
                            style={{ lineHeight: "1.5" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            10.
                                                        </span>
                                                    </strong>
                                                </span>
                                            </strong>
                                        </span>
                                        &nbsp;
                                    </strong>
                                    CONTRIBUTION LICENSE
                                </span>
                            </strong>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                By posting your Contributions to any part of the Services, you
                                automatically grant, and you represent and warrant that you have the
                                right to grant, to us an unrestricted, unlimited, irrevocable,
                                perpetual, non-exclusive, transferable, royalty-free, fully-paid,
                                worldwide right, and license to host, use, copy, reproduce,
                                disclose, sell, resell, publish, broadcast, retitle, archive, store,
                                cache, publicly perform, publicly display, reformat, translate,
                                transmit, excerpt (in whole or in part), and distribute such
                                Contributions (including, without limitation, your image and voice)
                                for any purpose, commercial, advertising, or otherwise, and to
                                prepare derivative works of, or incorporate into other works, such
                                Contributions, and grant and authorize sublicenses of the foregoing.
                                The use and distribution may occur in any media formats and through
                                any media channels.
                            </span>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                This license will apply to any form, media, or technology now known
                                or hereafter developed, and includes our use of your name, company
                                name, and franchise name, as applicable, and any of the trademarks,
                                service marks, trade names, logos, and personal and commercial
                                images you provide. You waive all moral rights in your
                                Contributions, and you warrant that moral rights have not otherwise
                                been asserted in your Contributions.
                            </span>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                We do not assert any ownership over your Contributions. You retain
                                full ownership of all of your Contributions and any intellectual
                                property rights or other proprietary rights associated with your
                                Contributions. We are not liable for any statements or
                                representations in your Contributions provided by you in any area on
                                the Services. You are solely responsible for your Contributions to
                                the Services and you expressly agree to exonerate us from any and
                                all responsibility and to refrain from any legal action against us
                                regarding your Contributions.
                            </span>
                        </div>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: 1 }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                We have the right, in our sole and absolute discretion, (1) to edit,
                                redact, or otherwise change any Contributions; (2) to re-categorize
                                any Contributions to place them in more appropriate locations on the
                                Services; and (3) to pre-screen or delete any Contributions at any
                                time and for any reason, without notice. We have no obligation to
                                monitor your Contributions.
                            </span>
                        </div>
                    </div>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="thirdparty"
                    style={{ lineHeight: "1.5" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span
                                    style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                                >
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            11.
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                            &nbsp;
                        </span>
                        THIRD-PARTY WEBSITES AND CONTENT
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        The Services may contain (or you may be sent via the Site) links to
                        other websites ("Third-Party Websites") as well as articles,
                        photographs, text, graphics, pictures, designs, music, sound, video,
                        information, applications, software, and other content or items
                        belonging to or originating from third parties ("Third-Party Content").
                        Such <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
                        and <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
                        are not investigated, monitored, or checked for accuracy,
                        appropriateness, or completeness by us, and we are not responsible for
                        any Third-Party Websites accessed through the Services or any{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
                        posted on, available through, or installed from the Services, including
                        the content, accuracy, offensiveness, opinions, reliability, privacy
                        practices, or other policies of or contained in the{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites or
                        the <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content.
                        Inclusion of, linking to, or permitting the use or installation of any{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites or
                        any <span style={{ fontSize: "14.6667px" }}>Third-Party&nbsp;</span>
                        Content does not imply approval or endorsement thereof by us. If you
                        decide to leave the Services and access the{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites or
                        to use or install any{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content, you
                        do so at your own risk, and you should be aware these Legal Terms no
                        longer govern. You should review the applicable terms and policies,
                        including privacy and data gathering practices, of any website to which
                        you navigate from the Services or relating to any applications you use
                        or install from the Services. Any purchases you make through{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites will
                        be through other websites and from other companies, and we take no
                        responsibility whatsoever in relation to such purchases which are
                        exclusively between you and the applicable third party. You agree and
                        acknowledge that we do not endorse the products or services offered on{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites and
                        you shall hold us blameless from any harm caused by your purchase of
                        such products or services. Additionally, you shall hold us blameless
                        from any losses sustained by you or harm caused to you relating to or
                        resulting in any way from any{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content or
                        any contact with{" "}
                        <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="sitemanage"
                    style={{ lineHeight: "1.5" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    12.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            SERVICES MANAGEMENT
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    We reserve the right, but not the obligation, to: (1) monitor the Services
                    for violations of these Legal Terms; (2) take appropriate legal action
                    against anyone who, in our sole discretion, violates the law or these
                    Legal Terms, including without limitation, reporting such user to law
                    enforcement authorities; (3) in our sole discretion and without
                    limitation, refuse, restrict access to, limit the availability of, or
                    disable (to the extent technologically feasible) any of your Contributions
                    or any portion thereof; (4) in our sole discretion and without limitation,
                    notice, or liability, to remove from the Services or otherwise disable all
                    files and content that are excessive in size or are in any way burdensome
                    to our systems; and (5) otherwise manage the Services in a manner designed
                    to protect our rights and property and to facilitate the proper
                    functioning of the Services.
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="ppyes"
                    style={{ lineHeight: "1.5" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span
                                    style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                                >
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            13.
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                            &nbsp;
                        </span>
                        PRIVACY POLICY
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        We care about data privacy and security. Please review our Privacy
                        Policy:
                        <strong>
                            &nbsp;<span style={{ color: "rgb(0, 58, 250)" }}>__________</span>
                        </strong>
                        . By using the Services, you agree to be bound by our Privacy Policy,
                        which is incorporated into these Legal Terms. Please be advised the
                        Services are hosted in __________. If you access the Services from any
                        other region of the world with laws or other requirements governing
                        personal data collection, use, or disclosure that differ from applicable
                        laws in{" "}
                        <span
                            style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            __________
                        </span>
                        , then through your continued use of the Services, you are transferring
                        your data to{" "}
                        <span
                            style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            __________
                        </span>
                        , and you expressly consent to have your data transferred to and
                        processed in{" "}
                        <span
                            style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            __________
                        </span>
                        .
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="terms"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    14.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            TERM AND TERMINATION
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        These Legal Terms shall remain in full force and effect while you use
                        the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
                        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
                        OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
                        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
                        OR DELETE YOUR ACCOUNT AND&nbsp;ANY CONTENT OR INFORMATION THAT YOU
                        POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        If we terminate or suspend your account for any reason, you are
                        prohibited from registering and creating a new account under your name,
                        a fake or borrowed name, or the name of any third party, even if you may
                        be acting on behalf of the third party. In addition to terminating or
                        suspending your account, we reserve the right to take appropriate legal
                        action, including without limitation pursuing civil, criminal, and
                        injunctive redress.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="modifications"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    15.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            MODIFICATIONS AND INTERRUPTIONS
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        We reserve the right to change, modify, or remove the contents of the
                        Services at any time or for any reason at our sole discretion without
                        notice. However, we have no obligation to update any information on our
                        Services. We will not be liable to you or any third party for any
                        modification, price change, suspension, or discontinuance of the
                        Services.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        We cannot guarantee the Services will be available at all times. We may
                        experience hardware, software, or other problems or need to perform
                        maintenance related to the Services, resulting in interruptions, delays,
                        or errors. We reserve the right to change, revise, update, suspend,
                        discontinue, or otherwise modify the Services at any time or for any
                        reason without notice to you. You agree that we have no liability
                        whatsoever for any loss, damage, or inconvenience caused by your
                        inability to access or use the Services during any downtime or
                        discontinuance of the Services. Nothing in these Legal Terms will be
                        construed to obligate us to maintain and support the Services or to
                        supply any corrections, updates, or releases in connection therewith.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="law"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    16.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            GOVERNING LAW
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                        }}
                    >
                        These Legal Terms are governed by and interpreted following the laws of
                        France, and the use of the United Nations Convention of Contracts for
                        the International Sales of Goods is expressly excluded. If your habitual
                        residence is in the EU, and you are a consumer, you additionally possess
                        the protection provided to you by obligatory provisions of the law in
                        your country to residence. Starmaze and yourself both agree to submit to
                        the non-exclusive jurisdiction of the courts of __________, which means
                        that you may make a claim to defend your consumer protection rights in
                        regards to these Legal Terms in France, or in the EU country in which
                        you reside.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="disputes"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    17.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            DISPUTE RESOLUTION
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_2"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>Informal Negotiations</strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span style={{ fontSize: 15 }}>
                        To expedite resolution and control the cost of any dispute, controversy,
                        or claim related to these Legal Terms (each a "Dispute" and
                        collectively, the "Disputes") brought by either you or us (individually,
                        a "Party" and collectively, the "Parties"), the Parties agree to first
                        attempt to negotiate any Dispute (except those Disputes expressly
                        provided below) informally for at least thirty (30) days before
                        initiating arbitration. Such informal negotiations commence upon written
                        notice from one Party to the other Party.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_2"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>Binding Arbitration</strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    Any dispute arising from the relationships between the Parties to these
                    Legal Terms shall be determined by one arbitrator who will be chosen in
                    accordance with the Arbitration and Internal Rules of the European Court
                    of Arbitration being part of the European Centre of Arbitration having its
                    seat in Strasbourg, and which are in force at the time the application for
                    arbitration is filed, and of which adoption of this clause constitutes
                    acceptance. The seat of arbitration shall be France. The language of the
                    proceedings shall be __________. Applicable rules of substantive law shall
                    be the law of France.
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_2"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>Restrictions</strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    The Parties agree that any arbitration shall be limited to the Dispute
                    between the Parties individually. To the full extent permitted by law, (a)
                    no arbitration shall be joined with any other proceeding; (b) there is no
                    right or authority for any Dispute to be arbitrated on a class-action
                    basis or to utilize class action procedures; and (c) there is no right or
                    authority for any Dispute to be brought in a purported representative
                    capacity on behalf of the general public or any other persons.
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_2"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>Exceptions to Informal Negotiations and Arbitration</strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    The Parties agree that the following Disputes are not subject to the above
                    provisions concerning informal negotiations binding arbitration: (a) any
                    Disputes seeking to enforce or protect, or concerning the validity of, any
                    of the intellectual property rights of a Party; (b) any Dispute related
                    to, or arising from, allegations of theft, piracy, invasion of privacy, or
                    unauthorized use; and (c) any claim for injunctive relief. If this
                    provision is found to be illegal or unenforceable, then neither Party will
                    elect to arbitrate any Dispute falling within that portion of this
                    provision found to be illegal or unenforceable and such Dispute shall be
                    decided by a court of competent jurisdiction within the courts listed for
                    jurisdiction above, and the Parties agree to submit to the personal
                    jurisdiction of that court.
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="corrections"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    18.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            CORRECTIONS
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    There may be information on the Services that contains typographical
                    errors, inaccuracies, or omissions, including descriptions, pricing,
                    availability, and various other information. We reserve the right to
                    correct any errors, inaccuracies, or omissions and to change or update the
                    information on the Services at any time, without prior notice.
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="disclaimer"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span style={{ fontSize: 19, color: "rgb(0, 0, 0)" }}>
                        <strong>
                            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                <strong>
                                    <span
                                        style={{
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            fontSize: 19
                                        }}
                                    >
                                        <strong>
                                            <span
                                                style={{
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    fontSize: 19
                                                }}
                                            >
                                                19.
                                            </span>
                                        </strong>
                                    </span>
                                </strong>
                            </span>{" "}
                            DISCLAIMER
                        </strong>
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
                        THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                        IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES'
                        CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
                        THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                        (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
                        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
                        FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS
                        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                        AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
                        CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
                        VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                        THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
                        OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                        KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
                        OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
                        ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
                        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
                        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
                        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
                        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="liability"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    20.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            LIMITATIONS OF LIABILITY
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        <span data-custom-class="body_text">
                            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
                            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
                            USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
                            OF SUCH DAMAGES.
                        </span>{" "}
                        <span data-custom-class="body_text">
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
                            OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF
                            ANY, BY YOU TO US&nbsp;
                            <span
                                style={{
                                    fontSize: "11pt",
                                    color: "rgb(89, 89, 89)",
                                    textTransform: "uppercase"
                                }}
                            >
                                DURING THE six (6) mONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING
                            </span>
                            .
                        </span>
                        &nbsp;
                    </span>
                    <span data-custom-class="body_text">
                        CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                        IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                        THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR
                        LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="indemnification"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    21.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            INDEMNIFICATION
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        You agree to defend, indemnify, and hold us harmless, including our
                        subsidiaries, affiliates, and all of our respective officers, agents,
                        partners, and employees, from and against any loss, damage, liability,
                        claim, or demand, including reasonable attorneys’ fees and expenses,
                        made by any third party due to or arising out of: (1) your
                        Contributions;&nbsp;(<span style={{ fontSize: "14.6667px" }}>2</span>)
                        use of the Services; (<span style={{ fontSize: "14.6667px" }}>3</span>)
                        breach of these Legal Terms; (
                        <span style={{ fontSize: "14.6667px" }}>4</span>) any breach of your
                        representations and warranties set forth in these Legal Terms; (
                        <span style={{ fontSize: "14.6667px" }}>5</span>) your violation of the
                        rights of a third party, including but not limited to intellectual
                        property rights; or (<span style={{ fontSize: "14.6667px" }}>6</span>)
                        any overt harmful act toward any other user of the Services with whom
                        you connected via the Services. Notwithstanding the foregoing, we
                        reserve the right, at your expense, to assume the exclusive defense and
                        control of any matter for which you are required to indemnify us, and
                        you agree to cooperate, at your expense, with our defense of such
                        claims. We will use reasonable efforts to notify you of any such claim,
                        action, or proceeding which is subject to this indemnification upon
                        becoming aware of it.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="userdata"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    22.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            USER DATA
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        We will maintain certain data that you transmit to the Services for the
                        purpose of managing the performance of the Services, as well as data
                        relating to your use of the Services. Although we perform regular
                        routine backups of data, you are solely responsible for all data that
                        you transmit or that relates to any activity you have undertaken using
                        the Services. You agree that we shall have no liability to you for any
                        loss or corruption of any such data, and you hereby waive any right of
                        action against us arising from any such loss or corruption of such data.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="electronic"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    23.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        Visiting the Services, sending us emails, and completing online forms
                        constitute electronic communications. You consent to receive electronic
                        communications, and you agree that all agreements, notices, disclosures,
                        and other communications we provide to you electronically, via email and
                        on the Services, satisfy any legal requirement that such communication
                        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
                        US OR VIA THE SERVICES. You hereby waive any rights or requirements
                        under any statutes, regulations, rules, ordinances, or other laws in any
                        jurisdiction which require an original signature or delivery or
                        retention of non-electronic records, or to payments or the granting of
                        credits by any means other than electronic means.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="california"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    24.
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                                &nbsp;
                            </strong>
                            CALIFORNIA USERS AND RESIDENTS
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        If any complaint with us is not satisfactorily resolved, you can contact
                        the Complaint Assistance Unit of the Division of Consumer Services of
                        the California Department of Consumer Affairs in writing at 1625 North
                        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
                        at (800) 952-5210 or (916) 445-1254.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="misc"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}>
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    25.
                                                </span>
                                            </strong>
                                        </span>
                                        &nbsp;
                                    </strong>
                                    &nbsp;
                                </span>
                                &nbsp;
                            </strong>
                            MISCELLANEOUS
                        </span>
                        &nbsp;
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        These Legal Terms and any policies or operating rules posted by us on
                        the Services or in respect to the Services constitute the entire
                        agreement and understanding between you and us. Our failure to exercise
                        or enforce any right or provision of these Legal Terms shall not operate
                        as a waiver of such right or provision. These Legal Terms operate to the
                        fullest extent permissible by law. We may assign any or all of our
                        rights and obligations to others at any time. We shall not be
                        responsible or liable for any loss, damage, delay, or failure to act
                        caused by any cause beyond our reasonable control. If any provision or
                        part of a provision of these Legal Terms is determined to be unlawful,
                        void, or unenforceable, that provision or part of the provision is
                        deemed severable from these Legal Terms and does not affect the validity
                        and enforceability of any remaining provisions. There is no joint
                        venture, partnership, employment or agency relationship created between
                        you and us as a result of these Legal Terms or use of the Services. You
                        agree that these Legal Terms will not be construed against us by virtue
                        of having drafted them. You hereby waive any and all defenses you may
                        have based on the electronic form of these Legal Terms and the lack of
                        signing by the parties hereto to execute these Legal Terms.
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="contact"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                            <span style={{ fontSize: 19 }}>
                                <strong>
                                    <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                        <strong>
                                            <span
                                                style={{
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    fontSize: 19
                                                }}
                                            >
                                                <strong>
                                                    <span
                                                        style={{
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            fontSize: 19
                                                        }}
                                                    >
                                                        26.
                                                    </span>
                                                </strong>
                                            </span>
                                            &nbsp;
                                        </strong>
                                    </span>
                                </strong>
                                CONTACT US
                            </span>
                        </span>
                    </strong>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span
                        style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166
                        }}
                    >
                        In order to resolve a complaint regarding the Services or to receive
                        further information regarding use of the Services, please contact us at:
                    </span>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>Starmaze</strong>
                        </span>
                    </span>
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span style={{ fontSize: 15 }}>
                            <strong>
                                <span style={{ color: "rgb(89, 89, 89)" }}>__________</span>
                            </strong>
                        </span>
                    </strong>
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <strong>France</strong>
                    </strong>
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                    <strong>
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            <strong>paul@starmaze.io</strong>
                        </span>
                    </strong>
                </div>
            </div>

        </>
    );
}

export default Terms;
