import React, { useEffect } from 'react';


function CheckoutError() {





    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', gap: '32px' }} >

            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36 66C52.5685 66 66 52.5685 66 36C66 19.4315 52.5685 6 36 6C19.4315 6 6 19.4315 6 36C6 52.5685 19.4315 66 36 66Z" stroke="#FF4057" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M45 27L27 45" stroke="#FF4057" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M27 27L45 45" stroke="#FF4057" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
            </svg>


            <div>
                <h3>Error during checkout</h3>
                <p style={{ margin: '0' }} >No payment was made. Try again </p>
            </div>

        </div>
    );


}

export default CheckoutError;
