import React, { useEffect, useState } from 'react';
import logoStarmaze from '../res/logo.png';

function CheckoutComplete() {
    const [downloadUrlMacIntel, setDownloadUrlMacIntel] = useState('');
    const [downloadUrlMacARM, setDownloadUrlMacARM] = useState('');
    const [downloadUrlWin, setDownloadUrlWin] = useState('');
    const [userOs, setUserOs] = useState('');
    const [lastUpdated, setLastUpdated] = useState('');
    const [versionName, setVersionName] = useState('');
    const [releaseUrl, setReleaseUrl] = useState('');

    useEffect(() => {
        const apiUrl = 'https://api.github.com/repos/Pleiode/AstroKeeper-Package/releases/latest';

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setUserOs(getOsIdentifier());

                // Filtrer et stocker les URLs en se basant sur les noms de fichier
                data.assets.forEach(asset => {
                    if (asset.name.includes('arm64-mac.zip')) {
                        setDownloadUrlMacARM(asset.browser_download_url);
                    } else if (asset.name.includes('-mac.zip') && !asset.name.includes('arm64')) {
                        setDownloadUrlMacIntel(asset.browser_download_url);
                    } else if (asset.name.includes('-win.zip')) {
                        setDownloadUrlWin(asset.browser_download_url);
                    }
                });

                if (data.published_at) {
                    setLastUpdated(formatDate(new Date(data.published_at)));
                }
                if (data.tag_name) {
                    setVersionName(data.tag_name);
                }
                if (data.html_url) {
                    setReleaseUrl(data.html_url);
                }
            })
            .catch(error => console.error('Error fetching release:', error));
    }, []);

    function getOsIdentifier() {
        const platform = navigator.platform.toLowerCase();
        if (/mac/i.test(platform)) { return 'mac'; }
        if (/win/i.test(platform)) { return 'win'; }
        return 'unknown';
    }

    function triggerDownload(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function formatDate(date) {
        return new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
        }).format(date);
    }

    return (
        <div className='download-container'>
            <div>
                <img style={{ width: '120px' }} src={logoStarmaze} alt="Logo Astrokeeper" />
            </div>
            <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
                <h3>AstroKeeper</h3>
                <p>Available on Windows and Mac</p>
                <div style={{ display: 'flex', gap: '16px', }}>


                    <button className='ak-btn' onClick={() => triggerDownload(downloadUrlWin)}>
                        Download for Windows
                    </button>

                    <button className='ak-btn' onClick={() => triggerDownload(downloadUrlMacIntel)}>
                        Download for Mac Intel
                    </button>
                    <button className='ak-btn' onClick={() => triggerDownload(downloadUrlMacARM)}>
                        Download for Mac ARM
                    </button>

                </div>

                {lastUpdated && versionName && (
                    <p>Last Updated {lastUpdated} - {' '}
                        <a href={releaseUrl} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                            See Changelog
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
}

export default CheckoutComplete;
