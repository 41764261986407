import React, { useState, useEffect } from 'react';

function CheckoutComplete() {
    const [downloadUrls, setDownloadUrls] = useState({
        win: '',
        macIntel: '',
        macArm: '',
    });

    useEffect(() => {
        const apiUrl = 'https://api.github.com/repos/Pleiode/AstroKeeper-Package/releases/latest';

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                // Initialiser les URLs de téléchargement à vide
                let urls = { win: '', macIntel: '', macArm: '' };

                // Filtre les assets par le nom de fichier pour chaque version
                data.assets.forEach(asset => {
                    if (asset.name.includes('win.zip')) {
                        urls.win = asset.browser_download_url;
                    } else if (asset.name.includes('arm64-mac.zip')) {
                        urls.macArm = asset.browser_download_url;
                    } else if (asset.name.includes('-mac.zip')) {
                        urls.macIntel = asset.browser_download_url;
                    }
                });

                // Mettre à jour l'état avec les URLs de téléchargement
                setDownloadUrls(urls);
            })
            .catch(error => console.error('Error fetching release:', error));
    }, []);

    // Fonction pour créer et déclencher le téléchargement
    function triggerDownload(fileUrl) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', ''); // L'attribut download est présent mais vide
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', gap: '32px' }} >

            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="71" height="71" stroke="black" />
                <path d="M66 33.24V36C65.9963 42.4692 63.9015 48.764 60.028 53.9454C56.1545 59.1269 50.7099 62.9174 44.5061 64.7517C38.3023 66.5859 31.6718 66.3657 25.6034 64.1237C19.535 61.8818 14.354 57.7383 10.8329 52.3112C7.3118 46.8841 5.63937 40.4641 6.06503 34.0089C6.49069 27.5537 8.99162 21.4089 13.1948 16.4912C17.3981 11.5734 23.0784 8.14611 29.3886 6.7204C35.6988 5.29469 42.3008 5.94697 48.21 8.57996" stroke="#4DF48E" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M66 12L36 42.03L27 33.03" stroke="#4DF48E" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <div>
                <h3>Account created!</h3>
                <p style={{ margin: '0' }} >Please select the version to download:</p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
                    <button className='ak-btn' onClick={() => triggerDownload(downloadUrls.win)}>Download for Windows</button>
                    <button className='ak-btn' onClick={() => triggerDownload(downloadUrls.macIntel)}>Download for Mac Intel</button>
                    <button className='ak-btn' onClick={() => triggerDownload(downloadUrls.macArm)}>Download for Mac ARM</button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutComplete;
