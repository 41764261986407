import React, { useEffect } from 'react';

const SubstackFeed = () => {
  useEffect(() => {
    // Configuration de la fenêtre SubstackFeedWidget
    window.SubstackFeedWidget = {
      substackUrl: "starmaze.substack.com",
      posts: 4,
      hidden: ["author"],
      colors: {
        primary: "var(--white)",
        secondary: "var(--grey10)",
        background: "var(--grey20)",
      }
    };

    // Chargement du script asynchrone
    const script = document.createElement('script');
    script.src = 'https://substackapi.com/embeds/feed.js';
    script.async = true;

    // Ajout du script au corps du document
    document.body.appendChild(script);

    // Nettoyage lors du démontage du composant
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Le tableau vide indique que ce code ne s'exécute qu'une fois, équivalent à componentDidMount dans les classes

  return <div id="substack-feed-embed"></div>;
};

export default SubstackFeed;
