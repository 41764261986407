import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MetaTags = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Mettre à jour le titre
    document.title = t('titleMeta');

    // Mettre à jour la description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', t('description'));
    }

  }, [t]); 

  return null;  
};

export default MetaTags;
