import React, { useEffect } from 'react';



const MyShop = () => {
    useEffect(() => {
        // Configuration pour le shop
        const spreadShopConfig = {
            shopName: 'starmaze',
            locale: 'fr_EU',
            prefix: 'https://starmaze.myspreadshop.net',
            baseId: 'myShop'
        };

        // Ajout de la configuration à la fenêtre globale
        window.spread_shop_config = spreadShopConfig;

        // Création et ajout du script
        const script = document.createElement('script');
        script.src = "https://starmaze.myspreadshop.net/shopfiles/shopclient/shopclient.nocache.js";
        script.async = true;
        document.body.appendChild(script);

        // Nettoyage lors du démontage du composant
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        
        <div id="myShop">
            <a href="https://starmaze.myspreadshop.net">starmaze</a>
        </div>
    );
};

export default MyShop;
