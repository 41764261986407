import React, { useState } from 'react';
import { ChevronDown } from 'react-feather'; // Importer ChevronDown de react-feather

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const questionsAnswers = [
        {
            question: "How does importing photos into AstroKeeper work?",
            answer: "When you import photos into AstroKeeper, the software generates two versions for each photo: a 'Preview' image for the grid view, and a 'Full-Quality' .png image that maintains every detail, perfect for fullscreen viewing."
        },
        {
            question: "What image formats can I use with AstroKeeper?",
            answer: "AstroKeeper supports a wide range of image formats including png, jpg, tiff, fit, xisf and CR2, ensuring compatibility with the most commonly used formats by photographers and astronomers."
        },
        {
            question: "Does AstroKeeper read and manage image metadata?",
            answer: "Yes, AstroKeeper specializes in automatically reading and integrating metadata for PNG, JPG, and TIFF image formats. This enables the automatic import of basic information such as ISO sensitivity, camera type, camera model, date, and camera settings and more..."
        },   
        {
            question: "Are my photos uploaded to the cloud or servers when using AstroKeeper?",
            answer: "For now, your photos are securely stored locally on your computer with AstroKeeper, ensuring your privacy. In the future, we plan to create a large database of user photos to support participatory science and provide a valuable resource for scientists. This will be done with your explicit consent, maintaining a focus on security and user control."
        },
        /* 
        {
            question: "Will AstroKeeper remain free to use?",
            answer: "AstroKeeper is currently in free early access as we continue refining the software. Future updates may introduce a pricing model for the final version, aimed at sustaining high-quality features and support."
        },*/
        {
            question: "Which operating systems are compatible with AstroKeeper?",
            answer: "AstroKeeper supports various operating systems including Windows and macOS, with ongoing efforts to expand our support and ensure accessibility for all users interested in exploring the cosmos."
        },
        {
            question: "What should I do if I run into problems with AstroKeeper?",
            answer: "If you encounter any issues or have questions, please contact our support team using the provided contact details. We're committed to providing assistance and ensuring a smooth experience with AstroKeeper."
        },
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <div style={{ textAlign: 'center' }}>
                <h4 style={{ margin: 0 }}>Find your answers</h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                {questionsAnswers.map((qa, index) => (
                    <div key={index} className="accordion">
                        <div
                            className="accordion-header"
                            onClick={() => toggleAccordion(index)}
                            style={{
                                backgroundColor: activeIndex === index ? 'rgb(78, 64, 206)' : 'var(--grey20)',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderRadius: activeIndex === index ? '10px 10px 0px 0px' : '10px',

                            }}
                        >
                            <h3>{qa.question}</h3>
                            <ChevronDown
                                style={{ transform: activeIndex === index ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s ease' }}
                            />
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-content" >
                                <p>{qa.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
