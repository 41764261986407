import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import logo from './logo.svg';
import './App.css';
import Saturn from './res/img/saturn.webp'; // Importe l'image
import Logo from './res/img/logo.svg'
import LogoMobile from './res/img/logo-mobile.svg'
import Share from './res/img/1.webp'
import Astronomy from './res/img/2.webp'
import Community from './res/img/3.webp'
import LogoOuranos from './res/img/ouranos-logo.svg'
import OuranosImg from './res/img/ouranos-main.png'
import Ouranos1 from './res/img/ouranos-1.png'
import Ouranos2 from './res/img/ouranos-2.png'
import Ouranos3 from './res/img/ouranos-3.png'
import Playstore from './res/img/playstore.png'
import Appstore from './res/img/appstore.png'
import Linkedin from './res/img/linkedin-box.svg'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MetaTags from './components/MetaTags';
import News from './components/news';
import MyShop from './components/Shop';
import LogoAK from './res/logo.png'

import CheckoutComplete from './components/CheckoutComplete';
import CheckoutError from './components/CheckoutError';
import AstroKeeper from './components/Astrokeeper';
import Register from './components/Register';
import Download_Astrokeeper from './components/Download_Astrokeeper';
import { Helmet } from 'react-helmet';
import Video1 from './res/img/astrokeeper/1.mp4';
import Terms from './components/Terms';
import Admin from './components/Admin'

function App() {


  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [parallaxPosition, setParallaxPosition] = useState({ x: 0, y: 0 });
  const [containerInfoVisible, setContainerInfoVisible] = useState(false);
  const [texteACopier, setTexteACopier] = useState('contact@starmaze.io');

  const copierTexte = () => {
    navigator.clipboard.writeText(texteACopier)
      .then(() => {
        alert(t('copyText'));
      })
      .catch(err => {
        console.error('Error : ', err);
      });
  };


  const GoToOuranos = () => {
    window.open('https://ouranos-app.com/', '_blank');
  };

  const GoToAstrokeeper = () => {
    window.open('https://starmaze.io/astrokeeper', '_blank');
  };

  const GoToNewsletter = () => {
    window.open('https://starmaze.substack.com/subscribe', '_blank');
  };



  useEffect(() => {
    // Fonction pour gérer le scroll
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Met à jour l'état en fonction de la position de défilement
      setScrolled(scrollPosition > 0);
    };

    // Ajoute un écouteur d'événement de défilement lors du montage du composant
    window.addEventListener('scroll', handleScroll);

    // Retire l'écouteur d'événement de défilement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Utilisation d'un tableau vide en tant que dépendance pour s'assurer que cela ne s'exécute qu'une seule fois


  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      setMousePosition({ x: clientX, y: clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  useEffect(() => {
    const updateParallaxPosition = () => {
      const targetX = mousePosition.x / parallaxAmount;
      const targetY = mousePosition.y / parallaxAmount;
      const dx = (targetX - parallaxPosition.x) * parallaxSmoothness;
      const dy = (targetY - parallaxPosition.y) * parallaxSmoothness;

      setParallaxPosition({ x: parallaxPosition.x + dx, y: parallaxPosition.y + dy });

      requestAnimationFrame(updateParallaxPosition);
    };

    requestAnimationFrame(updateParallaxPosition);

    return () => {
      // Nettoyez les ressources si nécessaire
    };
  }, [mousePosition, parallaxPosition]);


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth' // Scroll fluide
      });
    }
  };

  const parallaxAmount = 40; // Ajustez cette valeur pour contrôler l'intensité de l'effet de parallaxe
  const parallaxSmoothness = 0.05; // Ajustez cette valeur pour contrôler la fluidité de l'effet de parallaxe

  const saturnStyle = {
    transform: `translate(-${parallaxPosition.x}px, -${parallaxPosition.y}px)`
  };


  return (

    <Router>
      <Routes>

        <Route path="/astrokeeper" element={<AstroKeeper />} />
        <Route path="/astrokeeper/terms" element={<Terms />} />
        <Route path="/admin" element={<Admin />} />

        <Route path="/register" element={<Register />} />


        <Route path="/checkoutcomplete" element={<CheckoutComplete />} />
        <Route path="/checkouterror" element={<CheckoutError />} />

        <Route path="/shop" element={<MyShop />} />
        <Route path="/" element={

          <div className="App" id='home'>
            <MetaTags />

            <Helmet>
              <title>Starmaze</title>
              <meta name="description" content="Software and applications, combining user-friendliness and innovation, for astronomers, from enthusiasts to professionals." />
            </Helmet>

            <div className='background ' >

              <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`} >
                <img className='logoStarmaze' src={Logo} alt="logo-starmaze" ></img>
                <img className='logoStarmazeMobile' src={LogoMobile} alt="logo-starmaze" ></img>

                <ul>

                  <a onClick={() => scrollToSection('solution')}>{t('solution')}</a>
                  <a onClick={() => scrollToSection('news')}>{t('news')}</a>

                  <a href="https://starmaze.myspreadshop.net" target="_blank" rel="noopener noreferrer">{t('shop')}</a>


                  <a onClick={() => scrollToSection('contact')}>{t('contact')}</a>
                </ul>
              </nav>


              <div className='bruit-effect' >
                <img className='Saturn-img ' style={{ ...saturnStyle }} src={Saturn} alt="Saturn" ></img>
              </div>


              <div className='animate__animated animate__fadeInUp container-main'  >

                <h1 className='animate__animated animate__fadeInUp'  >{t('title')}</h1>
                <p className='animate__animated animate__fadeInUp'  >{t('description')}</p>
                <div className='animate__animated animate__fadeInUp' style={{ display: 'flex', gap: '8px' }} >
                  <button onClick={() => scrollToSection('solution')} >{t('solutionbutton')}
                  </button>
                  <button className='secondary-btn' onClick={GoToNewsletter} >{t('newsbutton')}
                    <span class="material-icons">open_in_new</span>
                  </button>
                </div>

              </div>

            </div>


            <div style={{ display: 'flex', overflow: 'auto', marginBlock: '5em', gap: '40px' }} >
              <div id="service" className='container-info' >
                <img loading="lazy" src={Share} alt="share" ></img>
                <div  >

                  <h2>{t('passionTitle')}</h2>



                  <p>{t('passionText')}</p>

                </div>
              </div>


              <div className='container-info ' >
                <div>

                  <h2>{t('astroTitle')}</h2>

                  <p>{t('astroText')}</p>

                </div>
                <img loading="lazy" src={Astronomy} alt="astronomy" ></img>
              </div>



              <div className='container-info' >
                <img loading="lazy" src={Community} alt="comunity" ></img>
                <div>

                  <h2>{t('shareTitle')}</h2>

                  <p>{t('shareText')}</p>

                </div>
              </div>

            </div>







            <section id="solution" >


              <h4>{t('solutionTitle')}</h4>


              <div className='container-ak'>


                <div  >
                  <img loading="lazy" className='logoOuranos' src={LogoAK} alt="ouranos" ></img>
                  <h2>{t('astrokeeperTitle')}</h2>
                  <div style={{ display: 'flex', gap: '24px' }} >
                    <button onClick={GoToAstrokeeper} >{t('gobuttonAK')}
                      <span class="material-icons">open_in_new</span>

                    </button>

                  </div>
                </div>


                <video style={{ width: '60%', height: '100%' }} autoPlay playsinline loop muted preload="auto" src={Video1} ></video>



              </div>


              <div style={{ marginTop: '4em' }} className='container-ouranos' >
                <div style={{ textAlign: 'left' }} >

                  <img loading="lazy" className='logoOuranos' src={LogoOuranos} alt="ouranos" ></img>

                  <h2>{t('ouranosTitle')}</h2>

                  <div style={{ display: 'flex', gap: '24px' }} >
                    <button onClick={GoToOuranos} >{t('gobutton')}
                      <span class="material-icons">open_in_new</span>

                    </button>
                    <div className='container-ouranos-button' >
                      <a href='https://apps.apple.com/app/ouranos-weather-astronomy/id6456041564' target="_blank">
                        <img loading="lazy" style={{ width: '34px', display: 'flex', textAlign: 'center' }} src={Appstore} alt="ouranos appstore" ></img>
                      </a>
                      <a href='https://play.google.com/store/apps/details?id=com.ouranos_app.www.twa' target="_blank" >
                        <img loading="lazy" style={{ width: '34px', display: 'flex', textAlign: 'center' }} src={Playstore} alt="ouranos playstore" ></img>
                      </a>
                    </div>
                  </div>

                </div>
                <img loading="lazy" className='ouranos-img-main' src={OuranosImg} alt="ouranos" />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6em', marginTop: '8em', zIndex: '1' }} >
                  <div style={{ width: '100%', padding: '0', gap: '32px', border: 'none' }} className='container-info ' >
                    <div>
                      <h3>{t('ouranosTitle1')}</h3>

                      <p>{t('ouranosText1')}</p>

                    </div>
                    <img loading="lazy" src={Ouranos1} alt="ouranos assistant" ></img>
                  </div>
                  <div style={{ width: '100%', padding: '0', gap: '32px', border: 'none' }} className='container-info' >
                    <img loading="lazy" src={Ouranos2} alt="ouranos assistant" ></img>
                    <div>

                      <h3>{t('ouranosTitle2')}</h3>
                      <p>{t('ouranosText2')}</p>

                    </div>
                  </div>
                  <div style={{ width: '100%', padding: '0', gap: '32px', border: 'none' }} className='container-info ' >
                    <div>

                      <h3>{t('ouranosTitle3')}</h3>
                      <p>{t('ouranosText3')}</p>

                    </div>
                    <img loading="lazy" src={Ouranos3} alt="ouranos assistant" ></img>
                  </div>
                </div>
              </div>

            </section>


            <section id="news" style={{ marginBlock: '8em' }} >
              <div style={{}} >
                <h4>{t('newsSection')}</h4>
                <News />

              </div>
            </section>





            <section id="contact" style={{ paddingInline: '14%', paddingBlock: '10em', margin: 'auto' }} >
              <h3>{t('contactTitle')}</h3>
              <p>{t('contactText')}</p>

              <h4 style={{ marginBottom: '0' }} >{t('mailTitle')}</h4>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <a href="mailto:contact@starmaze.io" style={{ fontSize: '20px', textDecoration: 'underline' }} mail >contact@starmaze.io</a>
                <button className='secondary-btn' onClick={copierTexte} style={{ border: 'none' }} ><span class="material-icons">content_copy</span></button>
              </div>

              <h4 style={{ marginBottom: '0' }} >{t('socialTitle')}</h4>
              <a href='https://www.linkedin.com/company/starmaze/' target="_blank">
                <img loading="lazy" style={{ width: '24px', display: 'flex', textAlign: 'center', marginTop: '12px', margin: 'auto' }} src={Linkedin} alt="linkedin" ></img>
              </a>

            </section>




            <div style={{ textAlign: 'center', marginTop: '2em' }}>

              <h3>{t('newsTitle')}</h3>
              <p>{t('newsText')}</p>
              <iframe
                src="https://starmaze.substack.com/embed"
                width="100%"
                height="120"
                style={{ border: 'none', background: '#000000', margin: '0', textAlign: 'left' }}
                frameBorder="0"
                scrolling="no"
              ></iframe>
            </div>


            <footer className="footer">


              <div className="footer-content">

                <div className="footer-section">

                  <img style={{ width: '180px', height: '40px' }} className='logoStarmaze' src={Logo} alt="logo-starmaze" ></img>

                  <a href='https://www.linkedin.com/company/starmaze/' target="_blank">
                    <img loading="lazy" style={{ width: '24px', display: 'flex', textAlign: 'center', marginTop: '12px' }} src={Linkedin} alt="linkedin" ></img>
                  </a>

                </div>


                <div className="footer-section sitemap">
                  <ul>
                    <li>
                      <a onClick={() => scrollToSection('home')}>{t('home')}</a>
                    </li>
                    <li>
                      <a onClick={() => scrollToSection('service')}>{t('services')}</a>
                    </li>
                    <li>
                      <a onClick={() => scrollToSection('solution')}>{t('solution')}</a>
                    </li>
                    <li>
                      <a onClick={() => scrollToSection('contact')}>{t('contact')}</a>
                    </li>
                  </ul>
                </div>


                <div className="footer-section sitemap">
                  <ul>
                    <li>
                      <a href='https://starmaze.substack.com?utm_source=navbar&utm_medium=web&r=2va5tg' target='_blank' >NEWSLETTER</a>
                    </li>
                    <li>
                      <a onClick={() => scrollToSection('service')}>{t('legal')}</a>
                    </li>
                  </ul>
                </div>

              </div>
              <div className="footer-bottom">
                <p style={{ fontSize: '14px' }} >{t('copyright')}</p>
                <p style={{ fontSize: '20px' }} >❤️ 🔭</p>
              </div>
            </footer>

          </div>
        } />
      </Routes>
    </Router>


  );
}

export default App;
