import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Correction pour les chemins d'accès aux images des marqueurs
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const AffichageClientsParPays = () => {
    const [markers, setMarkers] = useState([]);
    const position = [46.2276, 2.2137]; // Position initiale centrée sur la France

    useEffect(() => {
        const fetchClientsParPays = async () => {
            try {
                const response = await fetch("https://us-central1-starmaze-d4663.cloudfunctions.net/listeClientsParPays");
                const clientsParPays = await response.json();
                fetchCountryCoordinates(Object.keys(clientsParPays), clientsParPays);
            } catch (error) {
                console.error("Erreur lors de la récupération des clients par pays :", error);
            }
        };

        const fetchCountryCoordinates = async (countryCodes, clientsParPays) => {
            const requests = countryCodes.map(code =>
                fetch(`https://restcountries.com/v3.1/alpha/${code}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data[0] && data[0].latlng) {
                            const { latlng } = data[0];
                            return { code, latlng, count: clientsParPays[code] };
                        }
                        return null;
                    })
                    .catch(error => {
                        console.error(`Erreur lors de la récupération des coordonnées pour le pays ${code}:`, error);
                        return null;
                    })
            );

            Promise.all(requests).then(results => {
                setMarkers(results.filter(marker => marker !== null));
            });
        };

        fetchClientsParPays();
    }, []);

    return (
        <div>
            <h2>Clients par Pays</h2>
            <MapContainer center={position} zoom={2} style={{ height: '500px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://carto.com/attributions">CARTO</a> contributors'
                />
                {markers.map(({ code, latlng, count }) => (
                    <Marker position={latlng} key={code}>
                        <Popup>
                            {code}: {count} clients
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default AffichageClientsParPays;
