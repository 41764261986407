import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Importez useLocation de react-router-dom

import { auth, firestore } from '../Firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, onSnapshot, getDoc } from "firebase/firestore";


// Ajoutez redirectPath comme prop avec une valeur par défaut '/home'
const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(true);
    const [userUid, setUserUid] = useState(null);
    const [stripeCustomerId, setStripeCustomerId] = useState(null);
    const [priceId, setPriceId] = useState('');
    const [loading, setLoading] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [hasSpecificSubscription, setHasSpecificSubscription] = useState(false);


    const location = useLocation(); // Utilisez le hook useLocation pour accéder à l'objet location
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const priceIdFromUrl = queryParams.get('priceId');
        if (priceIdFromUrl) {
            setPriceId(priceIdFromUrl);
        }
    }, [location]);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && priceId) {
                setUserUid(user.uid);
                setIsUserLoggedIn(true);

                //fetchStripeCustomerId(user, priceId);
            } else {
                setUserUid(null);
                setIsUserLoggedIn(false);
                setStripeCustomerId(null);
            }
        });

        return () => unsubscribe();
    }, [priceId]); // Ajouter priceId comme dépendance pour re-exécuter l'effet quand priceId change


    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);

        } catch (error) {
            alert(`Connection error: ${error.message}`);
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setLoading(true); // Début du chargement


        if (password !== confirmPassword) {
            alert("The passwords do not match.");
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await setDoc(doc(firestore, "users", userCredential.user.uid), {
                email: userCredential.user.email,
                createdAt: new Date(),
            });

            const subscriptionRef = doc(firestore, "users", userCredential.user.uid, "astrokeeper", "subscription");

            await setDoc(subscriptionRef, {
                type: 0, // Type d'abonnement, par exemple
                trialExpired: false // Utilisez false pour les valeurs booléennes au lieu de 0
            });

            setShowModal(true);

        } catch (error) {
            alert(`Registration error: ${error.message}`);
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    const fetchStripeCustomerId = async (user) => {
        // Définir la référence au document d'abonnement
        const subscriptionRef = doc(firestore, "users", user.uid, "astrokeeper", "subscription");

        let attempts = 0;
        const maxAttempts = 5; // Limiter le nombre de tentatives à 5
        const delayMs = 1000; // Attendre 1 seconde entre chaque tentative

        // Fonction pour attendre un certain délai
        const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        // Essayer de récupérer le document d'abonnement jusqu'à obtenir le 'type' ou atteindre le nombre maximal de tentatives
        while (attempts < maxAttempts) {
            const subscriptionSnap = await getDoc(subscriptionRef);

            if (subscriptionSnap.exists()) {
                const subscriptionData = subscriptionSnap.data();

                // Si le type d'abonnement est 1, l'utilisateur a déjà un abonnement
                if (subscriptionData.type === 1) {
                    setHasSpecificSubscription(true); // Met à jour l'état pour indiquer que l'utilisateur a déjà un abonnement
                    console.log("L'utilisateur a déjà un abonnement.");


                } else if (subscriptionData.type === 0) {
                    // Si le type d'abonnement est 0, configure l'écouteur pour les changements de l'ID client Stripe
                    const userRef = doc(firestore, "users", user.uid);
                    const unsubscribe = onSnapshot(userRef, (docSnap) => {
                        if (docSnap.exists() && docSnap.data().stripeCustomerId) {
                            const stripeCustomerId = docSnap.data().stripeCustomerId;
                            setStripeCustomerId(stripeCustomerId);
                            handleCreateCheckoutSession(priceId, user.uid, stripeCustomerId);
                        } else {
                            console.log("Chargement ou ID client Stripe non trouvé...");
                        }
                    });
                    return unsubscribe; // Stoppe la boucle et se désabonne de l'écoute des changements
                }
            } else {
                console.log(`Tentative ${attempts + 1}: Document d'abonnement non trouvé. Réessai dans ${delayMs / 1000} secondes...`);
            }

            await wait(delayMs); // Attends 1 seconde avant la prochaine tentative
            attempts++;
        }


        console.log("Nombre maximal de tentatives atteint sans succès.");
    };

    const handleCreateCheckoutSession = async (priceId, uid, customerId) => {
        console.log(`Creating checkout session for user ${uid} with Stripe Customer ID ${customerId}`);
        try {
            const response = await fetch("https://us-central1-starmaze-d4663.cloudfunctions.net/createCheckoutSession", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    priceId,
                    customerId,
                    userUid: uid,
                }),
            });
            const session = await response.json();
            console.log(session.url); // Gestion de l'URL de la session de paiement

            window.location.href = session.url;
        } catch (error) {
            console.error("Error creating checkout session:", error);
        }
    };


    const toggleSignUp = () => setIsSignUp(!isSignUp);


    const handleResetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            alert('Recovery email sent! Please check your email to reset your password.');
        } catch (error) {
            console.error("Error sending password reset email: ", error);
            alert('Failed to send password reset email. Please try again.');
        }
    };



    if (isUserLoggedIn) {
        navigate('/checkoutcomplete');


        if (hasSpecificSubscription) {
            return (
                <div className="modal">
                    <h3>You have already a subscription.</h3>
                </div>
            );
        }

        return (
            <>
                <div className="modal">
                    <div className="modalContent">
                        <h3>Please Wait...</h3>

                        <div style={{ margin: 'auto' }} className='spinner' >
                        </div>
                    </div>
                </div>
            </>
        );
    }


    return (
        <>
            <div className='login-window'>
                <div className='login-background'></div>

                <div className='login-form'>

                    <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_381_2700)">
                            <path d="M23.0041 0.5C10.2974 0.5 0 11.1548 0 24.3025C0 33.984 2.8766 41.1213 10.9049 44.8437C16.0949 47.3622 23.0041 40.4469 19.9842 33.1815C19.0353 30.5178 17.0056 28.4261 14.4312 27.4358L9.06798 25.3868C8.1026 25.0197 8.1026 23.6025 9.06798 23.2353L14.4312 21.1863C17.0056 20.2045 19.0271 18.1043 19.9842 15.4406L21.9645 9.89124C22.146 9.39607 22.5751 9.13994 23.0041 9.13994C23.4332 9.13994 23.8622 9.38753 24.0438 9.89124L26.024 15.4406C26.9729 18.1043 29.0027 20.196 31.577 21.1863L36.9403 23.2353C37.9057 23.6025 37.9057 25.0197 36.9403 25.3868L31.577 27.4358C29.0027 28.4176 26.9812 30.5178 26.024 33.1815C23.0041 40.4469 29.635 47.3622 34.8332 44.8437C42.8533 41.1299 46.0082 33.9926 46.0082 24.3025C46 11.1548 35.7026 0.5 23.0041 0.5Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_381_2700">
                                <rect width="46" height="46" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h1 style={{ fontSize: '32px' }}>{isSignUp ? 'Create an Starmaze account' : 'Login'}</h1>
                    <form onSubmit={isSignUp ? handleSignUp : handleLogin}>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '36px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }} >
                                <p>Email</p>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="example@gmail.com" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <p>Password</p>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="at least 6 caracters" />
                            </div>
                            {!isSignUp && (
                                <p>Forgot your password? <a onClick={() => handleResetPassword(email)}>Reset password</a></p>
                            )}
                            {isSignUp && (
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }} >
                                    <p>Confirm password</p>
                                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="" />
                                </div>
                            )}
                        </div>

                        <button style={{ marginTop: '2em', width: '100%', }} className='ak-btn' type="submit" disabled={loading}>
                            {loading ? (
                                <div className="loaderLogin" />
                            ) : isSignUp ? 'Registration' : 'Login'}
                        </button>

                    </form>


                    {isSignUp ? (
                        <p>Already registered? <a onClick={toggleSignUp}>Login</a></p>
                    ) : (
                        <p>Are you a new user? <a onClick={toggleSignUp}>Create an account</a></p>
                    )}

                </div>

            </div>

            {showModal && (
                <div className="modal">
                    <div className="modalContent">
                        <h3>Congratulations !</h3>
                        <p>Your registration has been successfully completed. You will be redirected to payment...</p>
                        <div style={{ margin: 'auto' }} className='spinner' >

                        </div>
                    </div>
                </div>
            )}



        </>
    );
};

export default Register;

